/** @jsxImportSource @emotion/react */
import { MenuPatternRowStyles } from './MenuPatternRow.style';

function MenuPatternRow({
    temporaryPatternList,
    deleteLines,
    handleChange,
    handleCheck,
}) {

    const style = MenuPatternRowStyles();

    return (
        temporaryPatternList.map((data, index) => {
            const { pattern, enabled, name } = data
            return (
                <div css={style.main} key={data.id}>
                    <div className="menu-patterns__results py-2 border-bottom">
                        <input
                            type="text"
                            value={pattern}
                            className="menu-patterns__input w-100 px-2"
                            placeholder="パターン"
                            onChange={(e) => handleChange(index, e)}
                            name="pattern" />
                        <input
                            className="form-check-input ms-2 my-0"
                            type="checkbox"
                            id="check-2"
                            checked={enabled ? true : false}
                            // value={enabled}
                            name="enabled"
                            onChange={(e) => handleCheck(index, e)} />
                        <input
                            type="text"
                            className="menu-patterns__input w-100 px-2"
                            placeholder="ルール名称"
                            value={name}
                            name="name"
                            onChange={(e) => handleChange(index, e)} />
                        <button
                            type="button"
                            className="menu-patterns__button-del px-2 btn btn-secondary"
                            onClick={() => (deleteLines(data.id))}>削除</button>
                    </div>
                </div>
            )
        })
    )
}

export default MenuPatternRow;