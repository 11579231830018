import React, {createContext, PropsWithChildren, useContext, useState} from 'react'
import {$EmailInfo, $EmailInfo$Client} from '../../graphql/queriesIO'

type $Context = {
  item:    $EmailInfo$Client | { id?: undefined }, // TODO: 空の object ではなく undefined にした方が無難。 =>  item?: $EmailInfo$Client,
  setItem: (value: $EmailInfo$Client) => void,
}


const initialContext: $Context = {
  item:    {},
  setItem: () => {},
}

// const Context = createContext(initial)

export const DataContextProvider = React.memo<PropsWithChildren<{
  Context: React.Context<$Context>,
  initial: $Context,
}>>((props) => {
  const [item, setItem] = useState<$Context['item']>(props.initial.item)

  return (
    <props.Context.Provider value={{ item, setItem }} >
      {props.children}
    </props.Context.Provider>
  )
})
DataContextProvider.displayName = 'EmailInfoItemDataContextProvider'

function createDataContext(): {
  Provider:    (props: PropsWithChildren) => ReturnType<typeof DataContextProvider>,
  useConsumer: () => $Context,
} {
  const Context = createContext(initialContext)
  return {
    Provider:    (props) => <DataContextProvider Context={Context} initial={initialContext} {...props} />,
    useConsumer: () => useContext(Context),
  }
}

export const currentEmailInfoItemContext = createDataContext()

