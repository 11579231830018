import { css } from '@emotion/react';

export const EmailsListStyle = () => ({
    headerGrid: () => css`
        display: grid;
        grid-template-columns: 10% 5% 36% 19% 11% 14%;;
        grid-gap: 3px;
        grid-auto-flow: row;
        font-size: 7px;
    `,
    container: () => css`
        overflow-y: scroll;
        gap: 5px;
    `
})