import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { $CheckResult, $Comment } from '../../graphql/queriesIO'
import { createComment, updateCheckResult } from '../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import * as idb from '../../utils/indexeddb'
import { tempCheckResultListContext } from './CheckResultListDataContext'

// Create a new React context using the createContext function.
// Define a state object for the context that will hold the list of records.
// Define a function in the context that will update a record in the state object.
// In the update function, make a call to the database API to update the record in the database.
// If the update is successful, update the record in the state object.
// Return the updated record from the context update function.

type Context = {
    record: $CheckResult | {}
    comment: $Comment | {}
    updateRecordRemotely: (value: $CheckResult) => void
    updateRecordLocally: (value: $CheckResult) => void
    createCommentRemotely: (value: $Comment) => void
}

const initialContext: Context = {
    record: {},
    comment: {},
    updateRecordRemotely: () => { },
    updateRecordLocally: () => { },
    createCommentRemotely: () => { }
}

// Define the context

// Define the context provider
//@ts-ignore
export const RecordContexProvider = React.memo<PropsWithChildren<{
    Context: React.Context<Context>
}>>((props) => {

    const checkResultsContext = tempCheckResultListContext.useConsumer()
    const setCheckResults = useCallback(checkResultsContext.setItems, [])

    const [record, setRecord] = useState({} as $CheckResult)   // Define the state object for the context
    const [comment, setComment] = useState({} as $Comment)

    // Define the update function (DynamoDB)
    // ↓
    const updateRecordRemotely = useCallback<(record: $CheckResult) => void>(async (record: $CheckResult) => {
        console.log('updating record remotely')
        try {
            const updatedData = await API.graphql(graphqlOperation(updateCheckResult, {
                input: {
                    id: record.id,
                    user_decision: record.user_decision,
                },
                comments: {
                    items: record.comments
                }
            })) as GraphQLResult<{ updateCheckResult: $CheckResult }>

            const result = updatedData.data?.updateCheckResult
            console.log('after updating Dynamo DB: ', result)

            setRecord(result as $CheckResult)
        } catch (error) {
            console.log(error)
            throw error
        }
    }, [])

    // Define the update function (use as a callback on subscription)
    // ↓
    const updateRecordLocally = useCallback<(record: $CheckResult) => void>(async (record: $CheckResult) => {
        try {
            await idb.putCheckResultToIdb(record)
            const updatedData = await idb.getCheckResult(record.mail_id) // TODO 更新されたチェック結果が一番上に表示するよう調整
            setCheckResults(updatedData)
        } catch (error) {
            console.log(error)
            throw error
        }
    }, [])

    const createCommentRemotely = useCallback<(newComment: $Comment) => void>(async (newComment: $Comment) => {
        try {
            const createdData = await API.graphql(graphqlOperation(createComment, {
                input: newComment
            })) as GraphQLResult<{ createComment: $Comment }>
            const result = createdData.data?.createComment ?? {} as $Comment
            console.log('created comment (response from DynamoDb): ', result)
            setComment(result)
        } catch (error) {
            console.log(error)
            throw error
        }
    }, [])

    return (
        <props.Context.Provider value={{
            record,
            comment,
            updateRecordRemotely,
            updateRecordLocally,
            createCommentRemotely
        }}>
            {props.children}
        </props.Context.Provider>
    );
})

type RecordContext = () => {
    Provider: (props: PropsWithChildren) => ReturnType<typeof RecordContexProvider>,
    useData: () => Context,
}

const createRecordContext: RecordContext = () => {
    const Context = createContext(initialContext)

    return {
        Provider: (props) => <RecordContexProvider Context={Context} {...props} />,
        useData: () => useContext(Context)
    }
}
export const RecordUpdateContext = createRecordContext()