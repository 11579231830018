import React, {createContext, PropsWithChildren, useCallback, useContext, useState} from 'react'
import {$EmailInfo, $EmailInfo$Client} from '../../graphql/queriesIO'
import {updateEmailInfo} from '../../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api-graphql'

type $Context = {
  items:          Array<$EmailInfo$Client>,
  setIsToArchive: (value: boolean) => void,
  setItems:       (value: Array<$EmailInfo$Client>) => void,
  isCheckedOne:   (compare: $EmailInfo$Client) => boolean,
  isCheckedAll:   (compare: Array<$EmailInfo$Client>) => boolean,
  toggleItem:     (value: $EmailInfo$Client) => void,
  toggleList:     (value: Array<$EmailInfo$Client>) => void,
  submitItem:     (value: $EmailInfo$Client, callback?: (value: $EmailInfo) => void) => Promise<$EmailInfo>,
  submitList:     (callbackPerItem?: (value: $EmailInfo) => void) => Promise<Array<$EmailInfo>>,
}
const initialContext: $Context = {
  items:          [],
  setIsToArchive: () => {},
  setItems:       () => {},
  isCheckedOne:   () => false,
  isCheckedAll:   () => false,
  toggleItem:     () => {},
  toggleList    : () => {},
  submitItem:     () => Promise.reject(),
  submitList:     () => Promise.reject(),
}

const DataContextProvider = React.memo<PropsWithChildren<{
  Context: React.Context<$Context>,
  isTemp?: boolean,
}>>((props) => {
  const [items, setItems] = useState(initialContext.items)
  const [isToArchive, setIsToArchive] = useState(false)

  const toggleItem = useCallback<$Context['toggleItem']>((value) => {
    const targetItems = [value, ...(value.revisionType === 'latest' ? value.oldRevisions : [])]
    const newItems = items.filter(i => !targetItems.some(value => i.id == value.id))
    if (newItems.length === items.length) {
      setItems([...newItems, ...targetItems])
    } else {
      setItems([...newItems])
    }
  }, [items])

  const toggleList = useCallback<$Context['toggleList']>((value) => {
    setItems(items.length > 0 ? [] : value)
  }, [items])

  const isCheckedOne = useCallback<$Context['isCheckedOne']>((compare) => {
    return items.some(i => i.id === compare.id)
  }, [items])

  const isCheckedAll = useCallback<$Context['isCheckedAll']>((compare) => {
    if (items.length === 0) return false
    const ids = items.map((_) => _.id)
    return compare.every(i => ids.includes(i.id))
  }, [items])

  const submitItem = useCallback<$Context['submitItem']>(async (obj/*, archived: 0|1*/, callback) => {
    const updateInput = await API.graphql(graphqlOperation(updateEmailInfo, {
      input: {
        id: obj.id,
        archived: isToArchive ? 1 : 0, // archived
      }
    })) as GraphQLResult<{ updateEmailInfo: $EmailInfo }>
    // console.log('updateInput: ', updateInput)
    const updatedData = updateInput.data!.updateEmailInfo
    // console.log('updated data: ', updatedData)

    await callback?.(updatedData)
    return updatedData
  }, [isToArchive])

  const submitList = useCallback<$Context['submitList']>(async (callbackPerItem) => {
    const updatedList= await Promise.all(items.map(async i => {
      // DynamoDB上で非表示にされたメールを1通づつ更新していく
      // ↓
      return await submitItem(i, callbackPerItem)
    }))
    setItems([])
    return updatedList
  }, [items, submitItem])

  return (
    <props.Context.Provider value={{
      items, setIsToArchive, setItems, toggleItem, toggleList,
      isCheckedOne, isCheckedAll,
      submitItem, submitList
    }}>
      {props.children}
    </props.Context.Provider>
  )
})
DataContextProvider.displayName = 'ArchiveTargetListDataContextProvider'


function createDataContext(params?: {
  isTemp: boolean,
}): {
  Provider:    (props: PropsWithChildren) => ReturnType<typeof DataContextProvider>,
  useConsumer: () => $Context,
} {
  const Context = createContext(initialContext)
  return {
    Provider:    (props) => <DataContextProvider Context={Context} {...params} {...props} />,
    useConsumer: () => useContext(Context),
  }
}

export const archiveTargetListDataContext = createDataContext()
