/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import { CustomDropdownStyles } from './CustomDropdown.style'
import { $CheckResult$Client, $Comment } from '../../../graphql/queriesIO'

import { CurrentUserContext } from '../../../contexts/CurrentUserContext';
import { RecordUpdateContext } from '../../../contexts/data/CheckResultUpdateDataContext';

// bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

type CustomDropdownProps = {
    record: $CheckResult$Client
}

type Statuses = {
    NG: string[]
    OK: string[]
    EXCLUDE: string[]
}

const statuses: Statuses = {
    NG: ['OK', '対象外'],
    OK: ['NG', '対象外'],
    EXCLUDE: ['NG', 'OK']
}

const CustomDropdown = ({
    record,

}: CustomDropdownProps) => {

    const style = CustomDropdownStyles()

    const { currentUser } = useContext(CurrentUserContext)
    const { comment, updateRecordRemotely, createCommentRemotely } = RecordUpdateContext.useData()

    const userDecision = record.user_decision || undefined
    const originalDecision = record.decision
    const currentStatus: string = userDecision ?? originalDecision // 過去に変更があれば変更後の判定を表示する

    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const [showCommentInput, setShowCommentInput] = useState<boolean>(false)
    const [selectedOption, setSelectedOption] = useState<string>("")
    const [inputValue, setInputValue] = useState('') // コメントのインプット値

    // ドロップダウンに表示するオプション
    const selectOptions = () => {
        return (
            Object.keys(statuses).map((s, idx) => {
                const status = s === 'EXCLUDE' ? '除外' : s
                if (status === currentStatus) return
                return (

                    <Dropdown.Item
                        key={idx}
                        eventKey={status}
                        onClick={handleItemClick}
                    >
                        <span>{`${currentStatus} → ${status}`}</span>
                    </Dropdown.Item>
                )
            })
        )
    }

    const toggleCommentInput = () => setShowCommentInput(!showCommentInput)

    //@ts-ignore
    const handleItemClick = (e) => {
        e.preventDefault()
        setShowDropdown(true)
        toggleCommentInput()
        console.log(showDropdown)
    }

    //@ts-ignore
    const handleOptionSelect = (eventKey, event) => {
        event.preventDefault();
        setSelectedOption(eventKey);
    };

    const handleDropdownToggle = (nextShow: boolean, meta: any) => {
        setShowDropdown(!showDropdown)
    }

    //@ts-ignore
    const handleClose = (e) => {
        // e.target.value = ""  リセットしなくていいかも？
        toggleCommentInput()

    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setInputValue("")
        setShowCommentInput(false)

        const newComment: $Comment = {
            tenant: record.tenant,
            check_result_id: record.id,
            comment_body: inputValue,
            previous_status: currentStatus,
            new_status: selectedOption,
            changed: new Date().toISOString().slice(0, -5) + '+09:00',
            editor: currentUser.email,
            // check_result: null,
            // createdAt: new Date().toISOString().slice(0, -5) + '+09:00',
            // updatedAt: new Date().toISOString().slice(0, -5) + '+09:00',
        }
        await createCommentRemotely(newComment)
        console.log('created comment in dynamo db. moving onto updating check result')

        //@ts-ignore
        record.comments?.items.unshift(comment)
        record.user_decision = selectedOption

        console.log('record after being updated: ', record)
        // console.log('comment after being updated: ', comment)

        await updateRecordRemotely(record)
    }

    return !showCommentInput ?
        (
            <Dropdown
                //onToggle={toggleShow}
                css={style.main}
                show={showDropdown}
                onSelect={handleOptionSelect}
                onToggle={handleDropdownToggle}
            >
                <Dropdown.Toggle
                    className='dropdown__toggle'
                    variant="null"
                >
                    {!userDecision ? originalDecision : (userDecision === originalDecision ? userDecision : `${originalDecision} → ${userDecision}`)}
                </Dropdown.Toggle>

                <Dropdown.Menu
                    className='dropdown__menu'
                    show={true}>
                    {selectOptions()}
                </Dropdown.Menu>
            </Dropdown >
        )
        :
        (
            <div css={style.commentArea} >
                <div className='dropdown__form-container'>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>
                                {`${currentStatus} → ${selectedOption}`}
                            </Form.Label>
                            <Form.Control
                                className='dropdown__input'
                                as="textarea"
                                rows={3}
                                size="sm"
                                value={inputValue}
                                placeholder='コメントを入力'
                                onChange={(e) => setInputValue(e.target.value)} />
                        </Form.Group>
                        <div className='d-flex flex-row justify-content-end'>
                            <Button
                                className='me-2 dropdown__button'
                                variant="null"
                                type="button"
                                size="sm"
                                onClick={handleClose}
                            >
                                キャンセル
                            </Button>
                            <Button
                                className='dropdown__button'
                                variant="null"
                                size="sm"
                                type="submit"
                            >
                                送信
                            </Button>
                        </div>

                    </Form>
                </div>
            </div>
        )
}

export default React.memo(CustomDropdown);

// type PopoverProps = {
//     // handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void
//     showPopover: boolean
// }

// const DropdownPopover = ({
//     showPopover
// }: PopoverProps) => {
//     const [show, setShow] = useState<boolean>(showPopover)
//     const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
//         e.preventDefault()
//         setShow(false)
//     }

//     return (
//         <Popover show={true} id="dropdown-popover-comment">
//             <Popover.Header as="h3">コメント</Popover.Header>
//             <Popover.Body>
//                 <Form>
//                     <Form.Group
//                         className="mb-3"
//                         controlId="exampleForm.ControlTextarea1"
//                     >
//                         {/* <Form.Label>Example textarea</Form.Label> */}
//                         <Form.Control as="textarea" rows={3} />
//                     </Form.Group>
//                 </Form>
//                 <Button type='submit' variant="secondary" onClick={handleSubmit}>
//                     保存
//                 </Button>
//             </Popover.Body>
//         </Popover>
//     )
// }