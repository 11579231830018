import React, {PropsWithChildren, useEffect, useMemo ,useRef} from 'react'
import {createPortal} from 'react-dom'
import createCache from '@emotion/cache'
import { CacheProvider, EmotionCache } from '@emotion/react'

export const ReactShadowDom = React.memo<PropsWithChildren<{
  withStyleSheets?: Array<CSSStyleSheet>,
  disabledEmotion?: boolean,
}>>((props) => {
  const root = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!root.current) return
    root.current.attachShadow({ mode: 'open' })
  }, [])

  useEffect(() => {
    if (root.current?.shadowRoot) root.current.shadowRoot.adoptedStyleSheets = props.withStyleSheets ?? []
  }, [props.withStyleSheets])

  return (
    <div ref={root}>
      {root.current?.shadowRoot && createPortal(
        <ReactShadowDomProvider shadowRoot={root.current.shadowRoot} disabledEmotion={props.disabledEmotion}>
          {props.children}
        </ReactShadowDomProvider>,
        root.current.shadowRoot,
      )}
    </div>
  )
})
ReactShadowDom.displayName = 'ReactShadowDom'

const ReactShadowDomProvider = React.memo<PropsWithChildren<{
  shadowRoot:       ShadowRoot,
  disabledEmotion?: boolean,
}>>((props) => {
  const cache = useMemo<EmotionCache|null>(() => {
    return props.disabledEmotion
      ? null
      : createCache({
        container: props.shadowRoot,
        key: 'react-shadow'
      })
  }, [props.disabledEmotion])

  if (cache) return (
    <CacheProvider value={cache}>
      {props.children}
    </CacheProvider>
  )

  return (
    <>
      {props.children}
    </>
  )
})
ReactShadowDomProvider.displayName = 'ReactShadowDomProvider'
