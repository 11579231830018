import { css } from '@emotion/react';

export const SettingsOpsBarStyles = () => ({
    main: () => css`
        .settings-bar {
            height: 40px;
        }

        .settings-bar__lable {
            font-size: 11px;
        }

        .settings-bar__btn-save,
        .settings-bar__btn-cancel {
            transition: .2s ease-in-out 0s;
            background-color: #f0f0f0;
            margin-right: 5px;
            &:disabled {
                border: none;
            }
            &:hover {
                background-color: #ced0d2;
            }
        }
    `
})