/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { MenuMailAddressesRowStyles } from './MenuMailAddressesRow.style';
// import './SettingsNewMailLine.css';

function MenuMailAddressesRow({
    temporaryList,
    isValidEmail,
    deleteLines,
    handleChange, }) {

    const style = MenuMailAddressesRowStyles();

    return (
        temporaryList.map((data, index) => {
            const { email_address } = data;
            console.log(isValidEmail(email_address))
            return (
                <div css={style.main} key={data.id}>
                    <div className={`menu-addresses__results py-2 px-1 border-bottom`}>
                        <input
                            type="email"
                            value={email_address}
                            className="menu-addresses__input w-100 px-2"
                            placeholder="Email"
                            onChange={(e) => handleChange(index, e)}
                            name={email_address} />
                        <span css={style.hidden(isValidEmail(email_address))} className={`error-message text-danger`}>メールアドレスを入力してください。</span>
                        <button
                            type="button"
                            className="menu-addresses__button-del px-2 btn btn-secondary"
                            onClick={() => (deleteLines(data.id))}>削除</button>
                    </div>
                </div>
            )
        })
    )
}

export default MenuMailAddressesRow;