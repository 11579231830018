/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
// import './Header.css';
import { css } from '@emotion/react';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

function Header({
    signOut
}) {

    const pathname = useLocation().pathname;
    const { currentUser } = useContext(CurrentUserContext);

    const title = () => {
        if (pathname === '/sharedbox') {
            return '共有ボックス';
        } else if (pathname === '/settings') {
            return '設定';
        } else if (pathname === '/') {
            return '個人ボックス'
        } else if (pathname === '/archive') {
            return 'アーカイブ'
        }
    }

    return (
        <header css={css`min-height:80px`} className="d-flex flex-row justify-content-end align-items-center w-100 p-3 border-bottom bg-body">
            <h1 className="me-auto mb-0 fs-4 fw-bold">{title()}</h1>
            {/* <span className="header_user-email fs-6 text-muted me-3">{currentUser ? currentUser.email : 'user@email.com'}</span> */}
            <span className="fs-6 text-muted me-5">{currentUser.username}</span>
            <button className="btn btn-light btn-sm me-3" type="button" onClick={signOut}>ログアウト</button>
        </header>
    )
}

export default Header;