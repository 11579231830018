//@ts-check
import React, { useState } from "react";
import Header from "../Header/Header";
import NavigationBar from "../NavigationBar/NavigationBar";
import Plan from "./Plan/Plan";
import Usage from "./Usage/Usage";
import './Contract.css';

// bootstrap
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

function Contract({
    currentUser,
    signOut,
}) {
    return (
        <>
            <NavigationBar />
            <div className="contract_main w-100 overflow-x-auto">
                <Header
                    currentUser={currentUser}
                    signOut={signOut} />
                <div className="contract_main-container w-100">
                    {/* <ContractPageNav /> 
                    <div className="contract_sub-container">
                        <Plan />
                        <Upgrade />
                    </div> */}
                    <div className="d-flex align-items-start">

                        <Tab.Container id="contract-tabs" defaultActiveKey="contract-plan">
                            <Row className="w-75">
                                <Col sm={2} className="pe-0 mt-5">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="contract-plan" className="rounded-0 text-start settings_nav-link">プラン確認・変更</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="contract-usage" className="rounded-0 text-start settings_nav-link">利用状況</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={10} className="ps-0">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="contract-plan">
                                            <Plan />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="contract-usage">
                                            <Usage />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
} export default Contract;