// @ts-check
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
// import './MenuMailAddresses.css';
import { MenuMailAddressesStyles } from './MenuMailAddresses.style';
import SettingsOpsBar from '../../SettingsOpsBar/SettingsOpsBar';
import { tooltipMessages } from "../../../utils/tooltopMessages";
import CustomToast from "../../utils/Toast/Toast";
import MenuMailAddressesRow from './MenuMailAddressesRow'

function MenuMailAddresses({
    // isVisible,
    isValidEmail,
    additionalMailAddresses,
    temporaryList,
    setTemporaryList,
    updateMailAddresses,
    createMailAddress,
    deletelMailAddress,
}) {

    const [isValid, setIsValid] = useState(true)
    const style = MenuMailAddressesStyles();

    // Toast用
    // ↓
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const toggleShowToast = () => setShowToast(!showToast);

    // ↑

    const addLine = () => {
        const lineInput = {
            email_address: "",
            id: new Date().getTime()
        }
        setTemporaryList([...temporaryList, lineInput]);
    }

    const deleteLines = (id) => {
        console.log('item', id)
        const lines = [...temporaryList];
        //lines.splice(index, 1);
        const result = lines.filter(i => i.id !== id)
        setTemporaryList(result);
    }

    const handleChange = (index, e) => {

        const { value } = e.target;
        const linesInput = [...temporaryList];
        linesInput[index]['email_address'] = value;
        // setIsValid(isValidEmail(value));
        setTemporaryList(linesInput);
        console.log('value', value);
    }


    const handleSubmitData = async () => {
        // 元のメールアドレスをarrayにまとめる
        const originalEmails = additionalMailAddresses.map(e => e.email_address);
        const originalIds = additionalMailAddresses.map(i => i.id);
        const currentIds = temporaryList.map(i => i.id);
        const areNonEmails = temporaryList.some(i => !isValidEmail(i.email_address));
        try {
            if (areNonEmails) {
                //setToastMessage(tooltipMessages[2]);
                throw new Error(tooltipMessages[2]);
            } else {
                // 新しく追加されたメールアドレス毎にデータをサーバーに送る
                temporaryList.map(item => {
                    if (!originalIds.includes(item.id)) {
                        createMailAddress(item);
                    } else if (!originalEmails.includes(item.email)) {
                        updateMailAddresses(item);
                    }
                })
                // リストから削除されたアドレスをサーバーからも削除する
                await originalIds.map(i => {
                    !currentIds.includes(i) && deletelMailAddress(i);
                })
                //setToastMessage(tooltipMessages[4]);
            }
        } catch (error) {
            //setToastMessage(tooltipMessages[2])
            console.log(error)
        } finally {
            setShowToast(true)
        }
    }

    const handleDiscard = async () => {
        await setTemporaryList(JSON.parse(localStorage.getItem('additionalMails') || '[]'));
        await setToastMessage(tooltipMessages[3]);
        setShowToast(true)
    }

    return (
        <>
            <CustomToast
                showToast={showToast}
                toastMessage={toastMessage}
                toggleShowToast={toggleShowToast} />
            <div css={style.main} className="d-flex flex-column">
                <SettingsOpsBar
                    handleSubmit={handleSubmitData}
                    handleDiscardChanges={handleDiscard}
                    isActive={true} />
                <div className={`menu-addresses_ d-flex flex-column bg-body shadow my-3 w-100 px-3`}>
                    <div className='menu-addresses__title-area d-flex flex-row pb-0 pt-3'>
                        <h3 className='menu-addresses__title px-2 pb-4 fw-semibold'>通知先メールアドレス</h3>
                    </div>
                    <div className="menu-addresses__headers border-bottom pb-4 pt-1 px-1" style={{ fontSize: 7 + 'px' }}>
                        <span className='set-item_header text-secondary-emphasis ps-2'>メールアドレス</span>
                    </div>
                    <MenuMailAddressesRow
                        temporaryList={temporaryList}
                        isValidEmail={isValidEmail}
                        deleteLines={deleteLines}
                        handleChange={handleChange} />
                    <button className='btn set-item_opts px-4 py-2 text-center rounded border-0 btn-transparent my-1' type="button" onClick={addLine} disabled={temporaryList.length > 10}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="green" className="bi bi-plus-circle-fill add-icon" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )
}

export default MenuMailAddresses;