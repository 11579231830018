import { css } from '@emotion/react';

export const NavigationBarStyles = () => ({
    navLable: () => css`
    font-size: 7px;
`,
    navElementsMinidth: () => css`
    min-width: 60px;
`,
    navLink: () => css`
    min-width: 60px;
    &:hover {
        background-color: #ced4da;
    }
    `
})