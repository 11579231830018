import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react'
import {$CheckResult, $CheckResult$Client} from '../../graphql/queriesIO'
import {currentEmailInfoItemContext} from './EmailInfoItemDataContext'
import {listCheckResultsByMailId} from '../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import * as idb from '../../utils/indexeddb'
import {useRefState} from '../../utils/react-hooks/useRefState'

type $Context = {
  items:    Array<$CheckResult$Client>,
  setItems: (value: Array<$CheckResult>) => void,

  // TODO: 以下、本来はサーバー側でチェックすべき項目だが、無理やりクライアントで対応
  hiddenItems:   Array<$CheckResult['id']>
  clearHiddenItems: () => void,
  addHiddenItem: (parms: { checkResultId: $CheckResult['id'] }) => void,
  toClientChecked: (items: Array<$CheckResult$Client>) => Array<$CheckResult$Client & {
    isHidden: boolean,
  }>,
}
const initialContext: $Context = {
  items:    [],
  setItems: () => {},
  hiddenItems:   [],
  clearHiddenItems: () => {},
  addHiddenItem: () => {},
  toClientChecked: () => [],
}

const DataContextProvider = React.memo<PropsWithChildren<{
  Context: React.Context<$Context>,
  isTemp?: boolean,
}>>((props) => {
  const [items, _setItems] = useState(initialContext.items)
  // number_in_same_mail をクライアントでセットする必要がある為、ラップしておく。
  const setItems = useCallback((value: Array<$CheckResult>) => {
    const newValue = value.map<$CheckResult$Client>((item, index) => ({
      ...item,
      number_in_same_mail: (index + 1).toString().padStart(2, '0'),
    }))
    _setItems(newValue)
  }, [])

  // addHiddenItem が連続して呼ばれる際に state だと反映しきれないケースがある為 ref で対応する。
  const [hiddenItemsRef, setHiddenItems] = useRefState<Array<$CheckResult['id']>>([])

  const clearHiddenItems = useCallback(() => {
    setHiddenItems([])
  }, [])
  const addHiddenItem = useCallback((parms: { checkResultId: $CheckResult['id'] }) => {
    const newItems = [
      ...hiddenItemsRef.current.filter(_ => _ !== parms.checkResultId),
      parms.checkResultId,
    ]
    // console.log({ hiddenItems: hiddenItemsRef.current, newItems })
    setHiddenItems(newItems)
  }, [])
  const toClientChecked = useCallback<$Context['toClientChecked']>((items) => {
    return items.map((item) => ({
      ...item,
      // isHidden: true, // テスト用
      isHidden: hiddenItemsRef.current.includes(item.id),
    }))
  }, [])


  /**
   * TODO: 以下からコピーしてるので、重複ロジックになっている。良きタイミングで統合が必要。
   * TODO: また、うまいこと GraphQL からデータが取得できていない気がするので、都度 fetch しておく。
   * TODO: client で採番してしまっている状況の為、 sort に関しても下記と揃えておかないとNG。
   * @link EmailItem.js
   */
  const getOrFetch = useCallback<(mailId: string) => Promise<Array<$CheckResult>>>(async (mailId: string) => {
    const localResults = await idb.getCheckResult(mailId) ?? [];
    if (localResults.length > 0) {
      return localResults
    }
    try {
      const checkResultsData = (
        await API.graphql(graphqlOperation(listCheckResultsByMailId, { mail_id: mailId, limit: 1000 }))
      ) as GraphQLResult<{ listCheckResultsByMailId: { items: Array<$CheckResult> } }>
      // localStorage.setItem(`checkResults${mailId}`, JSON.stringify(allResults)); // NG,判定不能、除外結果を配列に保存
      const allResults = (checkResultsData.data?.listCheckResultsByMailId.items ?? [])
        // .sort((a, b) => a.mail_format > b.mail_format ? 1 : -1)
        .sort((a, b) => a.id > b.id ? 1 : -1)
      idb.putCheckResultstoIdb(allResults).then()
      return allResults
    } catch (error) {
      console.log(error);
      throw error
    }
  }, [])

  const currentEmailInfoItem = currentEmailInfoItemContext.useConsumer()
  useEffect(() => {
    if (props.isTemp) return // temp のやつは currentEmailInfoItem を listen しない
    if (!currentEmailInfoItem.item.id) {
      setItems([])
    } else {
      getOrFetch(currentEmailInfoItem.item.id).then((result) => {
        setItems(result)
      })
    }
  }, [currentEmailInfoItem.item?.id, props.isTemp])

  return (
    <props.Context.Provider value={{ items, setItems, hiddenItems: hiddenItemsRef.current, clearHiddenItems, addHiddenItem, toClientChecked }}>
      {props.children}
    </props.Context.Provider>
  )
})
DataContextProvider.displayName = 'CheckResultListDataContextProvider'


function createDataContext(params?: {
  isTemp: boolean,
}): {
  Provider:    (props: PropsWithChildren) => ReturnType<typeof DataContextProvider>,
  useConsumer: () => $Context,
} {
  const Context = createContext(initialContext)
  return {
    Provider:    (props) => <DataContextProvider Context={Context} {...params} {...props} />,
    useConsumer: () => useContext(Context),
  }
}

export const currentCheckResultListContext = createDataContext()
// TODO: 上記のだけで足りる認識。
export const tempCheckResultListContext = createDataContext({ isTemp: true })
