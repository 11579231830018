import pako from 'pako'

/**
 * TODO: .graphqlconfig.yml での generator を変えると影響範囲が大きいため、一旦手書きで対応。
 * */
export type $EmailInfo = {
  id :                string,    // S3バケット保存時に付与されたメールのハッシュ値
  tenant :            string, // 会社識別子(cognitoユーザーグループ)(@auth用)
  tenantIndex :       string, // tenantと同じ値(@index用)
  sender :            string, // 送信者メールアドレス
  subject :           string, // メールタイトル
  received :          string, // メール受信日時(JST)
  checked? :          string, // メールチェック日時(JST)
  all_link? :         number, // メールのリンク総数
  ok_link? :          number, // 分析OKリンク総数
  ng_link? :          number, // 分析NGリンク総数
  exclude_link? :     number, // 分析除外リンク総数
  unprocessed_link? : number, // 判定不能リンク総数
  // html_body? :        string, // HTMLメール本文 => 廃止
  // text_body? :        string, // テキストメール本文 => 廃止
  html_result?:       string, // 分析済みのHTMLメール本文
  text_result?:       string, // 分析済みのテキストメール本文
  html_result_gzip?:  string, // 分析済みのHTMLメール本文を gzip & base64
  text_result_gzip?:  string, // 分析済みのテキストメール本文
  archived:           number, // アーカイブされているか（表示：0、非表示：1）
  read:               number, // 未読チェック（未読：0、既読：1）
  results: Array<$CheckResult>, // aタグ + imgタグ数分だけのチェック結果が入る // TODO: なぜか object のパターンがあるっぽいので注意
  // createdAt : any,
  // updatedAt : any,
}

// TODO: results には nextToken しか入ってなさそうなので、別途取得しないと使えない為、一旦 omit
export type $EmailInfo$Client = (
  $EmailInfo$Client$Parallel |
  $EmailInfo$Client$Latest |
  $EmailInfo$Client$Old
)
type $EmailInfo$Client$Parallel = Omit<$EmailInfo, 'results'> & {
  // results:  Array<$CheckResult$Client>, // {nextToken: any} , //
  revisionType: 'parallel',
  isMatchedFilter: boolean,
}
export type $EmailInfo$Client$Latest = Omit<$EmailInfo, 'results'> & {
  // results:  Array<$CheckResult$Client>, // {nextToken: any} , //
  revisionType: 'latest',
  oldRevisions: Array<$EmailInfo$Client$Old>,
  isMatchedFilter: boolean,
  isAnyOldRevisionMatchedFilter: boolean,
}
type $EmailInfo$Client$Old = Omit<$EmailInfo, 'results'> & {
  // results:  Array<$CheckResult$Client>, // {nextToken: any} , //
  revisionType: 'old',
  latestRevision: $EmailInfo$Client$Latest,
  isMatchedFilter: boolean,
}

export class EmailInfo {
  static getHtmlResult(item: $EmailInfo|$EmailInfo$Client) {
    return this.getResult(item.html_result_gzip, item.html_result)
  }

  static getTextResult(item: $EmailInfo|$EmailInfo$Client) {
    return this.getResult(item.text_result_gzip, item.text_result)
  }

  private static getResult(gzipped?: string, unzipped?: string) {
    if (gzipped) {
      // console.time('unzipped')
      const gzippedBase64 = gzipped
      const gzippedBinary = atob(gzippedBase64)
      const gzippedByteArray = Uint8Array.from(gzippedBinary, c => c.charCodeAt(0))
      const unzippedByteArray = pako.ungzip(gzippedByteArray)
      const unzippedText = new TextDecoder().decode(unzippedByteArray)
      // console.timeEnd('unzipped')
      // console.log({ gzippedBase64, gzippedBinary, gzippedByteArray, unzippedByteArray, unzippedText })
      return unzippedText
    }
    return unzipped ?? ''
  }
}

export type $CheckResult = {
  id :                          string, // 自動採番
  mail_id :                     $EmailInfo['id'], // S3バケット保存時に付与されたメールのハッシュ値
  tenant :                      string, // 会社識別子(cognitoユーザーグループ)
  decision :                    string, // 分析結果（OK, NG, 判定不能, 除外）
  html_tag? :                   string, // a, img タグの識別
  mail_format :                 string, // HTMLメール or テキストメール
  link_text :                   string, // aタグの場合はリンクテキスト, imgタグの場合はalt属性
  url :                         string, // aタグの場合はhref属性, imgタグの場合はsrc属性
  status_code? :                number, // ステータスコード
  status_title? :               string, // ステータスコードのタイトル
  status_code_description? :    string, // ステータスコードの説明(英語)
  status_code_description_ja? : string, // ステータスコードの説明(日本語)
  exclude_rule_name? :          string, // 除外された場合に除外ルール名が入る
  exclude_rule_pattern? :       string, // 除外された場合に除外ルールパターンが入る
  // スキーマ拡張とCommentタイプ作成の関係で追加：
  comments?:                    { items: Array<$Comment> },
  user_decision?:               string,

  // createdAt : any,
  // updatedAt : any,
}

export type $CheckResult$Client = $CheckResult & {
  number_in_same_mail: string, // ex: 0001 : from 1
}

export type $Comment = {
  tenant:              string,
  check_result_id:     string,
  comment_body:        string, 
  changed:             string, // 変更時刻
  previous_status:     string, // 変更前の判定
  new_status:          string, // 変更後の判定
  editor:              string, // 判定を変更した担当者
  check_result?:       $CheckResult | null,
  createdAt?:          any,
  updatedAt?:          any,
}

