//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import NavigationBar from '../NavigationBar/NavigationBar';
import Header from '../Header/Header';
import MenuGeneral from '../SettingsItem/MenuGeneral/MenuGeneral';
import MenuPatterns from '../SettingsItem/MenuPatterns/MenuPatterns';
import MenuMailAddresses from '../SettingsItem/MenuMailAddresses/MenuMailAddresses';
// import './Settings.css';
import { SettingsStyles } from './Settings.style';

// bootstrap
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

function Settings({
    additionalMailAddresses,
    patterns,
    temporaryList,
    updateMailAddresses,
    setTemporaryList,
    temporaryPatternList,
    setTemporaryPatternList,
    signOut,
    createMailAddress,
    deletelMailAddress,
    createPatterns,
    updatePatterns,
    deletePatterns,
}) {

    const [isValid, setIsValid] = useState(true);
    const style = SettingsStyles();

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    return (
        <>
            <NavigationBar />
            <div
                css={style.main} 
                className='set_main r w-100 overflow-x-auto'>
                <Header
                    signOut={signOut} />
                <div className="d-flex align-items-start">

                    <Tab.Container id="settings-tabs" defaultActiveKey="settings-general">
                        <Row className="w-75">
                            <Col sm={2} className="pe-0 mt-5">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="settings-general" className="rounded-0 text-start settings__nav-link">全般</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="settings-patterns" className="rounded-0 text-start settings__nav-link">対象外URL</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="settings-mails" className="rounded-0 text-start settings__nav-link">通知先メールアドレス</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={10} className="ps-0">
                                <Tab.Content>
                                    <Tab.Pane eventKey="settings-general">
                                        <MenuGeneral />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="settings-patterns">
                                        <MenuPatterns
                                            // isVisible={isPatternVisible}
                                            patterns={patterns}
                                            temporaryPatternList={temporaryPatternList}
                                            setTemporaryPatternList={setTemporaryPatternList}
                                            createPatterns={createPatterns}
                                            updatePatterns={updatePatterns}
                                            deletePatterns={deletePatterns} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="settings-mails">
                                        <MenuMailAddresses
                                            isValidEmail={isValidEmail}
                                            additionalMailAddresses={additionalMailAddresses}
                                            temporaryList={temporaryList}
                                            setTemporaryList={setTemporaryList}
                                            updateMailAddresses={updateMailAddresses}
                                            createMailAddress={createMailAddress}
                                            deletelMailAddress={deletelMailAddress} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default Settings;