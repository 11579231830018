//@ts-check
import React, { useEffect, useState, useContext } from "react";
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';

function MenuGeneral() {

    const { currentUser, tenantConfigs } = useContext(CurrentUserContext);

    return (
        <>
            <div className="d-flex flex-column">
                <div className={`ms-3 mt-4 d-flex flex-column`}>
                    <label className="set-item-gen_label pb-0 mb-2" htmlFor="set-item-gen_input-username">ユーザー名</label>
                    <p
                        className="set-item-gen_input-username text-light-emphasis fs-6 mb-3 ms-2"
                        id="set-item-gen_input-username"
                    >{currentUser.username}</p>
                    <label className="set-item-gen_label pb-0 mb-2" htmlFor="set-item-gen_input-email">チェック用メールアドレス</label>
                    <p
                        className="set-item-gen_input-email text-light-emphasis fs-6 mb-3 ms-2"
                        id="set-item-gen_input-email" >
                        {tenantConfigs.email_for_check}
                    </p>
                </div>
            </div>
        </>
    )
}

export default MenuGeneral;