//@ts-check
import { Heading, useTheme, Text, View, useAuthenticator, Button, Image, Link } from '@aws-amplify/ui-react';
import React from 'react';

// export const formFields = {
// 	signIn: {
// 		username: {
// 			placeholder: 'Enter Your Email Here',
// 			isRequired: true,
// 			label: 'Email:'
// 		},
// 	},
// 	confirmVerifyUser: {
// 		confirmation_code: {
// 			label: 'New Label',
// 			placeholder: 'Enter your Confirmation Code:',
// 			isRequired: false,
// 		},
// 	},
// };

// export const components = {
// 	VerifyUser: {
// 		Header() {
// 			const { tokens } = useTheme();
// 			return (
// 				<Heading
// 					padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
// 					level={3}
// 				>
// 					Enter Information:
// 				</Heading>
// 			);
// 		},
// 		Footer() {
// 			return <Text>Footer Information</Text>;
// 		},
// 	},

// 	ConfirmVerifyUser: {
// 		Header() {
// 			const { tokens } = useTheme();
// 			return (
// 				<Heading
// 					padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
// 					level={3}
// 				>
// 					Enter Information:
// 				</Heading>
// 			);
// 		},
// 		Footer() {
// 			return <Text>Footer Information</Text>;
// 		},
// 	},
// };

const components = {
	Header() {
		const { tokens } = useTheme();

		return (
			<View textAlign="center" padding={tokens.space.large}>
				{/* <img
					alt="Amplify logo"
					src="https://docs.amplify.aws/assets/logo-dark.svg"
				/> */}
			</View>
		);
	},

	// Footer() {
	// 	const { tokens } = useTheme();

	// 	return (
	// 		<View textAlign="left" paddingTop ={tokens.space.large}>
	// 			<Text color={tokens.colors.neutral[80]} fontSize={tokens.fontSizes.small} lineHeight={tokens.fontSizes.large}>
	// 				お客様は、続行することにより、AWSカスタマーアグリーメントまたはAWSのサービスに関するその他の契約、およびプライバシー通知に同意することになります。このサイトは必須のCookieを使用します。詳細については、Cookieに関する通知をご参照ください。
	// 			</Text>
	// 			<Text 
	// 				color={tokens.colors.neutral[80]} 
	// 				fontSize={tokens.fontSizes.small} 
	// 				lineHeight={tokens.fontSizes.large}
	// 				marginTop={`${tokens.space.xl}`}>
	// 				ご利用開始の前に、<Link color={tokens.colors.green[80]} fontWeight={tokens.fontWeights.semibold}>本サービスに関する利用規定</Link>をご確認ください。
	// 			</Text>
	// 		</View>
	// 	);
	// },

	SignIn: {
		Header() {
			const { tokens } = useTheme();

			return (
				<Heading
					padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
					level={3}
				>
					Sign In
				</Heading>
			);
		},
		Footer() {
			const { toResetPassword } = useAuthenticator();
			const { tokens } = useTheme();

			return (
				<View textAlign="center">
					<Button
						fontWeight="normal"
						onClick={toResetPassword}
						size="small"
						variation="link"
						color={tokens.colors.green[80]}
					>
						Reset Password
					</Button>
				</View>
			);
		},
	},

	SetupTOTP: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
					padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
					level={3}
				>
					Enter Information:
				</Heading>
			);
		},
		// Footer() {
		// 	return <Text>Footer Information</Text>;
		// },
	},
	ConfirmSignIn: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
					padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
					level={3}
				>
					Enter Information:
				</Heading>
			);
		},
		// Footer() {
		// 	return <Text>Footer Information</Text>;
		// },
	},
	ResetPassword: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
					padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
					level={3}
				>
					Enter Information:
				</Heading>
			);
		},
		// Footer() {
		// 	return <Text>Footer Information</Text>;
		// },
	},
	ConfirmResetPassword: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading
					padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
					level={3}
				>
					Enter Information:
				</Heading>
			);
		},
		// Footer() {
		// 	return <Text>Footer Information</Text>;
		// },
	},
};

const formFields = {
	signIn: {
		username: {
			placeholder: 'Enter Your Email Here',
			isRequired: true,
			label: 'Email:'
		},
	},
	confirmVerifyUser: {
		confirmation_code: {
			label: 'New Label',
			placeholder: 'Enter your Confirmation Code:',
			isRequired: false,
		},
	},
	forceNewPassword: {
		password: {
			placeholder: 'Enter your Password:',
		},
	},
	resetPassword: {
		username: {
			placeholder: 'Enter your email:',
		},
	},
	confirmResetPassword: {
		confirmation_code: {
			placeholder: 'Enter your Confirmation Code:',
			label: 'New Label',
			isRequired: false,
		},
		confirm_password: {
			placeholder: 'Enter your Password Please:',
		},
	},
	setupTOTP: {
		QR: {
			totpIssuer: 'test issuer',
			totpUsername: 'amplify_qr_test_user',
		},
		confirmation_code: {
			label: 'New Label',
			placeholder: 'Enter your Confirmation Code:',
			isRequired: false,
		},
	},
	confirmSignIn: {
		confirmation_code: {
			label: 'New Label',
			placeholder: 'Enter your Confirmation Code:',
			isRequired: false,
		},
	},
}

export default components;