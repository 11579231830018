//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
// import './SettingsItemPattern.css';
import { MenuPatternsStyles } from './MenuPatterns.style';
import SettingsOpsBar from '../../SettingsOpsBar/SettingsOpsBar';
import MenuPatternRow from './MenuPatternRow';
import { tooltipMessages } from "../../../utils/tooltopMessages";
import CustomToast from "../../utils/Toast/Toast";


function MenuPatterns({
    // isVisible,
    patterns,
    temporaryPatternList,
    setTemporaryPatternList,
    createPatterns,
    updatePatterns,
    deletePatterns, }) {

    // Toast用
    // ↓
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const toggleShowToast = () => setShowToast(!showToast);
    const style = MenuPatternsStyles();

    // ↑

    const addLine = () => {
        const lineInput = {
            pattern: "",
            name: "",
            enabled: false,
            id: new Date().getTime(),
            type: 0,
        }
        setTemporaryPatternList([...temporaryPatternList, lineInput]);
    }

    const deleteLines = (id) => {
        const lines = [...temporaryPatternList];
        setTemporaryPatternList(lines.filter(i => i.id !== id));
    }

    const handleChange = (index, e) => {

        const { name, value } = e.target;
        const linesInput = [...temporaryPatternList];
        linesInput[index][name] = value;
        setTemporaryPatternList(linesInput);
        console.log('temporaryPatternList on change: ', temporaryPatternList)
    }

    const handleCheck = (index, e) => {
        const { checked } = e.target;
        const linesInput = [...temporaryPatternList];
        linesInput[index]['enabled'] = checked;
        setTemporaryPatternList(linesInput);
    }

    const handleSubmitData = async () => {

        // 元のメールアドレスをarrayにまとめる
        const currentIds = temporaryPatternList.map(t => t.id)
        const originalIds = patterns.map(p => p.id)
        const isEmptyPattern = temporaryPatternList.some(p => p.pattern === "");
        const isEmptyName = temporaryPatternList.some(p => p.name === "");
        console.log(isEmptyPattern);
        console.log(isEmptyName);

        try {
            if (isEmptyPattern) {
                setToastMessage(tooltipMessages[6]);
                throw new Error(tooltipMessages[6]);
            } else if (isEmptyName) {
                setToastMessage(tooltipMessages[7]);
                throw new Error(tooltipMessages[7]);
            } else {
                await temporaryPatternList.map(item => {
                    if (!originalIds.includes(item.id)) {
                        createPatterns(item);
                    } else {
                        updatePatterns(item);
                    }
                })
                await patterns.map(p => {
                    if (!currentIds.includes(p.id)) {
                        deletePatterns(p);
                    }
                })
                setToastMessage(tooltipMessages[5])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShowToast(true)
        }
    }

    const handleDiscard = async () => {
        console.log(patterns)
        await setTemporaryPatternList(JSON.parse(localStorage.getItem('specificPatterns') || '[]'));
        await setToastMessage(tooltipMessages[3]);
        setShowToast(true);
    }

    return (
        <>
            <CustomToast
                showToast={showToast}
                toastMessage={toastMessage}
                toggleShowToast={toggleShowToast} />
            <div css={style.main} className="d-flex flex-column">
                <SettingsOpsBar
                    handleSubmit={handleSubmitData}
                    handleDiscardChanges={handleDiscard}
                    isActive={true} />
                <div className={`set-item d-flex flex-column bg-body shadow my-3 px-3 w-100`}>
                    <div className='menu-patterns__title-area d-flex flex-row pb-0 pt-3'>
                        <h3 className='menu-patterns__title me-auto px-2 pb-4 fw-semibold'>チェック対象外URLパターン</h3>
                    </div>
                    <div className="menu-patterns__headers border-bottom pb-4 pt-1 px-2" style={{ fontSize: 7 + 'px' }}>
                        <span className='menu-patterns__header text-secondary-emphasis'>パターン</span>
                        <span className='menu-patterns__header text-secondary-emphasis'>有効</span>
                        <span className='menu-patterns__header text-secondary-emphasis'>ルール名称</span>
                        <span className='menu-patterns__header text-secondary-emphasis'></span>
                    </div>
                    <MenuPatternRow
                        temporaryPatternList={temporaryPatternList}
                        deleteLines={deleteLines}
                        handleChange={handleChange}
                        handleCheck={handleCheck} />
                    <button className='btn menu-patterns__opts px-4 py-2 text-center rounded border-0 btn-transparent my-1' type="button" onClick={addLine}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="green" className="bi bi-plus-circle-fill add-icon" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )
}

export default MenuPatterns;