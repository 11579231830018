//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Paginate from '../utils/Paginate/Paginate';
import { CheckResultsGridRowBody, CheckResultsGridRowHead } from './CheckResultsGrid';

import {CheckResultItemStyles} from './CheckResultItem.style';
import {currentCheckResultListContext} from '../../contexts/data/CheckResultListDataContext'

// receive an object with past checks

function CheckResultItemNG({ checkResults, isHTMLactive, isTEXTactive, isLoading }) {
    const currentCheckResultsContext = currentCheckResultListContext.useConsumer()

    const resultsHTML = currentCheckResultsContext.toClientChecked(
      checkResults.filter(result => result.decision === "NG" && result.mail_format === "html")
    )
    const resultsTEXT = currentCheckResultsContext.toClientChecked(
      checkResults.filter(result => result.decision === "NG" && result.mail_format === "text")
    )

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentHtmlItems = resultsHTML.slice(indexOfFirstItem, indexOfLastItem);
    const currentTextItems = resultsTEXT.slice(indexOfFirstItem, indexOfLastItem);

    const style = CheckResultItemStyles();

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (isHTMLactive) {
            if (currentPage !== Math.ceil(resultsHTML.length / itemsPerPage)) {
                setCurrentPage(currentPage + 1);
            }
        }
        if (isTEXTactive) {
            if (currentPage !== Math.ceil(resultsTEXT.length / itemsPerPage)) {
                setCurrentPage(currentPage + 1);
            }
        }
    };

    useEffect(() => {
        setCurrentPage(1)
    }, [isTEXTactive, isHTMLactive])

    return (
        <div css={style.main} className={`check-result-item d-flex flex-column bg-body shadow-sm my-1 mx-auto border-bottom`} style={{ width: 98 + '%' }}>
            <div className='check-result-item__title-area d-flex justify-content-end flex-row pb-2 pe-2'>
                
                <Paginate
                    itemsPerPage={itemsPerPage}
                    totalItems={isHTMLactive ? resultsHTML.length : resultsTEXT.length}
                    paginate={paginate}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    currentPage={currentPage}
                    fontSize={10} />

            </div>
            <CheckResultsGridRowHead variant={'CheckResult'} />
            {
                !isLoading ?
                    (resultsHTML.length > 0 ?
                        isHTMLactive &&
                        currentHtmlItems.map((result, idx) => {
                            return (
                                <CheckResultsGridRowBody key={idx} variant={'Data$Checkable'} record={result} />
                            )
                        })
                        :
                        (isHTMLactive &&
                            <CheckResultsGridRowBody variant={'Empty$Checkable'} />
                        ))
                    :
                    isHTMLactive &&
                    checkResults.length === 0 &&
                    (<CheckResultsGridRowBody variant={'Skeleton$Checkable'} subType={'usePlaceholderLg'} />)
            }

            {
                !isLoading ?
                    (resultsTEXT.length > 0 ?
                        isTEXTactive &&
                        currentTextItems.map((result, idx) => {
                            return (
                                <CheckResultsGridRowBody key={idx} variant={'Data$Checkable'} record={result} />
                            )
                        })
                        :
                        (isTEXTactive &&
                            <CheckResultsGridRowBody variant={'Empty$Checkable'} />
                        )
                    )
                    :
                    isTEXTactive &&
                    (<CheckResultsGridRowBody variant={'Skeleton$Checkable'} subType={'useFontSize'} />)
            }

            {/* {
                resultsTEXT.length > 0 &&
                isTEXTactive &&
                currentTextItems.map((result, idx) => {
                    return (
                        <div key={idx} className="check-result-item__results1 check-result-item__results py-2 px-1 border-bottom text-start" style={{ fontSize: 7 + 'px' }}>
                            <span className='px-2'>{result.decision}</span>
                            <span className='px-2'>{result.tag}</span>
                            <span className='px-2'>{result.link_text}</span>
                            <span className='px-2 text-start'>
                                <a href={`${result.url}`} target="_blank" className='check-result-item__col text-decoration-none text-success-emphasis'>{result.url}</a>
                            </span>
                            <span className='px-2'>{result.status_code}</span>
                            <span className='px-2'>{result.status_title}</span>
                            <span className='px-2'>{result.status_code_description_ja}</span>
                        </div>
                    )
                })
            } */}


        </div>
    )
}

export default CheckResultItemNG;