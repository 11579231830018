import { css } from '@emotion/react';

export const MenuMailAddressesRowStyles = () => ({
    main: () => css`
        .menu-addresses__results {
            display: grid;
            grid-template-columns: calc(100% - 270px) 200px 70px;
            grid-gap: 3;
            grid-auto-flow: column;
            align-items: center;
            font-size: 7px;
        }

        .menu-addresses__button-del {
            justify-self: end;
            width: 50px;
            --bs-btn-padding-y: .25rem;
            --bs-btn-padding-x: .5rem;
            --bs-btn-font-size: .6rem;
        }

        .menu-addresses__input {
            outline: none;
            border: none;
        }
    `,
    hidden: (isValid) => css`
        ${isValid ? 'visibility: hidden' : 'visibility: visible'};
    `
})