import { css } from "@emotion/react"

export const inputCheckBoxStyles = () => ({
  root: () => css`
    position: relative;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25em;
    font-size: 1em;
    height: 100%;
    
    * {
      padding: 0;
      margin: 0;
      white-space: nowrap;
    }
  `,
})


