/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import './Paginate.css';

function Paginate({
    itemsPerPage,
    totalItems,
    paginate,
    previousPage,
    nextPage,
    currentPage,
    fontSize,
}) {

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage) + 1; i++) {
        // (Math.abs(currentPage - i) <= 1) && pageNumbers.push(i);
        i === currentPage && pageNumbers.push(i)
    }

    return (
        <div aria-label="Page navigation example" className="ms-1 d-flex flex-row align-items-flex-end;">
            <button  
                onClick={previousPage} 
                css={css`
                    width:30px;
                    &:disabled {
                        background-color: transparent;
                    }
                `}
                className={`paginate__btn rounded border-0 me-1 refresh-btn`}
                disabled={currentPage === 1}>
                &laquo;
            </button>
            <div className="d-flex flex-row align-items-center">
                {pageNumbers.map((number) => (
                    
                    <span
                        key={number}
                        css={css`
                            border: none;
                            font-size: ${fontSize}px
                        `}
                        className={`btn btn-white fw-bold ${(number === 0 || number === Math.ceil(totalItems / itemsPerPage) + 1) && 'disabled'}`}
                        onClick={() =>  paginate(number)}>
                        { number }
                    </span>
                ))}
            </div>
            <button 
                onClick={nextPage} 
                css={css`
                    width:30px;
                    &:disabled {
                        background-color: transparent;
                    }
                `}
                className={`paginate__btn rounded border-0 me-1 refresh-btn`}
                disabled={(currentPage === Math.ceil(totalItems / itemsPerPage || currentPage === 1))}>
                &raquo;
            </button>
            <span css={css`
                font-size: ${fontSize - 3}px
            `} className="text-secondary text-nowrap">{`/ ${Math.ceil(totalItems / itemsPerPage) === 0 ? 1 : Math.ceil(totalItems / itemsPerPage)}`}</span>
        </div>
    )
}

export default Paginate;