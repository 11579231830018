import { css } from "@emotion/react"

export const emailContentBodyStyles = (params: {
  type:          'html'|'text',
  isHighlighted: boolean,
}) => ({
  root: () => css`
    position: relative;    
  `,
  originalShadowDom: () => css`
    .targeturl {
      ${params.isHighlighted ? css`
        outline: auto;
        outline-color: #f96e6e;
        background-color: #ff8989;
        animation:disappearing 3s linear infinite;
      ` : css`
        outline: none;
        outline-color: unset;
        background-color: transparent;
        animation: unset;
      `}
    }

    @keyframes disappearing {
      0% {
        outline-color: #f96e6e;
        background-color: #ff8989;
      }
      50% {
        outline-color: transparent;
        background-color:transparent;
      }
      100% {
        outline-color: #f96e6e;
        background-color:#ff8989;
      }
    }
  `,
  overlayNormalDom: () => css`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  `,
  overlayRoot: () => css`
    position: relative;
    height: 100%;
    width: 100%;
  `,
  overlayCheckpointNumber: (params: {
    top    : number,
    left   : number,
  }) => css`
    pointer-events: all;
    position: absolute;
    top:  ${params.top}px;
    left: ${params.left}px;
    border: 1px solid gray;
    color: gray;
    padding: 0.1em 0.2em;
    margin: clamp(-${params.top}px, -1em, 0px) clamp(-${params.left}px, -1em, 0px);
    font-size: xx-small;
    background: rgba(255,255,255,0.8);
  `,
})


