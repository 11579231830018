import { css } from '@emotion/react';

export const MenuMailAddressesStyles = () => ({
    main: () => css`
        .menu-addresses__headers{
            display: grid;
            grid-template-columns: 90% 10%;
            grid-gap: 3;
            grid-auto-flow: column;
            align-items: center;
        }

        .menu-addresses__header {
            font-size: 8px;
        }
        
        .menu-addresses__title {
            font-size: 14px;
        }

        .add-icon {
            transition: .2s ease-in-out 0s;
        }

        .add-icon:hover {
            transform: scale(1.05);
        }
    `
})