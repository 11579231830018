//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useContext, useCallback, useEffect, useState } from 'react';
// import './EmailItem.css';
import { EmailItemStyles } from './EmailItem.styles'
import { API, graphqlOperation } from 'aws-amplify';
import { listCheckResults, listCheckResultsByMailId } from '../../graphql/queries';
import CheckResultItemNG from '../CheckResultItems/CheckResultItemNG';
import CheckResultItemNA from '../CheckResultItems/CheckResultItemNA';
import CheckResultItemOK from '../CheckResultItems/CheckResultItemOK';
import CheckResultItemNoEval from '../CheckResultItems/CheckResultItemNoEval';
import * as idb from '../../utils/indexeddb';

// Bootstrap
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/esm/AccordionContext';
import Card from 'react-bootstrap/Card';
import CustomToggleButton from '../utils/CustomToggleButton/CustomToggleButton';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import { tempCheckResultListContext } from '../../contexts/data/CheckResultListDataContext'
import { InputToggle, useInputToggle } from './InputToggle'
import { currentEmailInfoItemContext } from '../../contexts/data/EmailInfoItemDataContext'
import {archiveTargetListDataContext} from '../../contexts/data/ArchiveTargetListDataContext'
import {useEmailInfoListDataContext} from '../../contexts/data/EmailInfoListDataContext'

// event listener: ckicking anywhere on the item shows() request content on the screen
// event listener: clicking on the 詳細 button shows RequestResultList down below

function EmailItem({
    idx,
    email,
    openedEmail,
    isHTMLactive,
    isTEXTactive,
    handleItemClick,
    setOpenedEmail,
    setIsHTMLActive,
    setIsTEXTActive,
    updateEmailsOnRead,
    isNested,
}) {
    const emailInfoListDataContext = useEmailInfoListDataContext()
    const currentEmailInfo = currentEmailInfoItemContext.useConsumer()
    const checkResultsContext = tempCheckResultListContext.useConsumer()
    const archiveTargetList  = archiveTargetListDataContext.useConsumer()
    const selectedEmail = currentEmailInfo.item

    // const [checkResults, setCheckResults] = useState([]);
    const checkResults = checkResultsContext.items
    const setCheckResults = useCallback(checkResultsContext.setItems, [])
    const [isLoading, setIsLoading] = useState(false);
    const [isRead, setIsRead] = useState(email.read === 1 ? true : false);
    const inputToggle = useInputToggle()
    useEffect(() => {
        inputToggle.setOpened(false)
    }, [emailInfoListDataContext.enabledThreadMode])
    const styles = EmailItemStyles();

    // Accordion用
    // ↓
    const { activeEventKey } = useContext(AccordionContext);

    const toggleRead = () => {
        if (!isRead) {
            setIsRead(true);
        }
    }
    // ↑

    // Tabs用
    // ↓
    const [keyForMailType, setKeyForMailType] = useState('html');
    const [keyForResultTypes, setKeyForResultTypes] = useState(`ng-links-${email.id}`);

    const handleMailTabSelect = (k) => {
        setKeyForMailType(k);
        if (k === 'html') {
            setIsHTMLActive(true);
            setIsTEXTActive(false);
        } else if (k === 'text') {
            setIsHTMLActive(false);
            setIsTEXTActive(true);
        }
    }
    // ↑

    const toggleMoreButton = () => {

        console.log('opened id subject', openedEmail.subject)
        if (openedEmail.id === email.id) {
            setOpenedEmail({});
        } else {
            setOpenedEmail(email);
            handleMoreBtnClick(email);
        }
    }

    const fetchCheckResults = async (email) => {
        console.log('fetching ckeckResults: Step 1');
        try {
            // const checkResultsData = await API.graphql(graphqlOperation(listCheckResults, { filter: { mail_id: { eq: email.id }, decision: { ne: "OK" } }, limit: 1000 }));
            const checkResultsData = await API.graphql(graphqlOperation(listCheckResultsByMailId, { mail_id: email.id, limit: 1000 }));
            const allResults = checkResultsData.data.listCheckResultsByMailId.items
                .sort((a, b) => a.id > b.id ? 1 : -1)
            setCheckResults(allResults);

            // localStorage.setItem(`checkResults${email.id}`, JSON.stringify(allResults)); // NG,判定不能、除外結果を配列に保存

            // fetch結果をIDBへ格納する
            // ↓
            idb.putCheckResultstoIdb(allResults);

            console.log('fetching check results for selected email');
            console.log('checkResults after fetching: ', allResults);
            console.log('fetching ckeckResults: Step 2');
        } catch (error) {
            console.log(error);
        }
    }

    const onItemClick = () => {
        handleItemClick(email);
    }

    // DynamoDBとIDB上でreadを更新するIDB上
    const handleMoreBtnClick = async (email) => {
        console.log(isRead)
        if (!isRead) {
            setIsRead(true);
        }

        try {
            // indexedDbからチェック結果を取る
            // ↓
            const localResults = await idb.getCheckResult(email.id) ?? [];
            console.log(localResults);

            // IDBにチェック結果がなかった場合、DynamoDBからfetchしてstateに保管する
            // IDBにチェック結果があった場合、fetchせずにstateに保管する
            // ↓
            if (localResults.length === 0) {
                await setIsLoading(true);
                await fetchCheckResults(email)
                await setIsLoading(false)
            } else {
                setCheckResults(localResults);
            }

            if (!isRead) {
                // email.read = 1;
                await updateEmailsOnRead(email, 1);
                // // DynamoDB上でread:1へ変更する
                // // ↓
                // await idb.putEmailToIdb(email);
            }

        } catch (error) {
            console.error(error)
        }
    }

    // useEffect(() => {

    //     !localStorage.getItem(`checkResults${email.id}`) &&
    //         localStorage.setItem(`checkResults${email.id}`, '[]');
    // }, [])

    // useEffect(() => {
    //     fetchCheckResults(email)
    //         .then(() => fetchOnlyOkCheckResults(email))
    // }, [emailList])

    const isSelected = !!selectedEmail.id && selectedEmail.id === email.id
    const isMatchedFilter = email.isMatchedFilter
    const isLatestOfSelected = !!selectedEmail.id && email.revisionType === 'latest' && email.oldRevisions.some((_) => _.id === selectedEmail.id)
    return (

        <>
            {/* <Accordion defaultActiveKey="0"> */}
            <Card
              css={styles.root({ isNested })}
              className="req-item rounded border-0"
            >
                <Card.Header
                    css={[styles.elementContainer({isSelected, isMatchedFilter, isLatestOfSelected, isRead}), styles.main]}
                    className={`border-start rounded border-end-0 border-top-0 border-bottom-0 ${isMatchedFilter ? 'border-danger' : 'border-success'} border-5 py-2 px-1`}
                >
                    <div
                      css={styles.elementContainerCheck()}
                      className={`d-flex justify-content-center ${selectedEmail.id === email.id ? 'bg-success-subtle' : isRead ? 'bg-body-secondary' : 'bg-white'}`}
                    >
                        <input
                            className="email-list__checkbox-item"
                            type="checkbox"
                            name="archived"
                            disabled={isNested}
                            checked={archiveTargetList.isCheckedOne(email)}
                            onChange={(e) => archiveTargetList.toggleItem(email)} />
                    </div>
                    {email.revisionType === 'latest' && (
                      <div css={styles.elementContainerArrowIcon()}>
                        {email.oldRevisions.length > 0 && <InputToggle {...inputToggle} />}
                      </div>
                    )}
                    <div className='email-item__inner-container' onClick={onItemClick}>
                        <div className="email-item__icon mx-2 ">
                            <div css={styles.elementContainerEmailIcon()}>
                                {isRead ?
                                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="green" className="bi bi-envelope-open" viewBox="0 0 16 16">
                                      <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                                  </svg>
                                  :
                                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="green" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                  </svg>}
                                {email.revisionType === 'latest' && email.oldRevisions.length > 0 && (
                                  <p css={styles.elementContainerEmailIconText()}>({email.oldRevisions.length + 1})</p>
                                )}
                            </div>
                        </div>
                        <div className="email-item__info-container d-flex flex-column">
                            <span className="email-item__email">{email.sender ? email.sender : 'email@adress.com'}</span>
                            <h3 className="email-item__title text text-wrap mb-0" style={{ fontSize: 13 + 'px' }}>{email.subject ? email.subject : 'Request Title'}</h3>
                        </div>
                        <div className="email-item__sammaries d-flex flex-column text-nowrap" style={{ fontSize: 8 + 'px' }}>
                            <span className={`email-item__sammary-item ${email.ng_link > 0 && 'fw-bold text-danger'}`}>{`NG数: ${email.ng_link || 0}`}</span>
                            <span className={`email-item__sammary-item ${email.unprocessed_link > 0 && 'fw-bold text-danger'}`}>{`判定不能数: ${email.unprocessed_link || 0}`}</span>
                            <span className="email-item__sammary-item">{`対象除外数: ${email.exclude_link || 0}`}</span>
                            <span className="email-item__sammary-item">{`OK数: ${email.ok_link || 0}`}</span>

                        </div>
                        <span className="email-item__time-stamp align-self-start text-nowrap text-end">{email.received && email.received.substring(0, 10)} <br /> {email.received.substring(11, 19)}</span>


                        <CustomToggleButton
                            eventKey={`key-${email.id}`}
                            callback={toggleMoreButton}
                            name={openedEmail.id === email.id ? '閉じる' : '詳細'}
                        />
                    </div>
                </Card.Header>

                <Accordion.Collapse eventKey={`key-${email.id}`}>

                    <Card.Body
                        className={`email-item__results-area bg-secondary-subtle flex-column align-items-center py-3 w-100`}
                    >

                        <Tabs
                            id="nav-tab-outer"
                            activeKey={isHTMLactive ? 'html' : 'text'}
                            onSelect={handleMailTabSelect}
                            className="d-flex flex-row border-0 "
                        >
                            <Tab
                                eventKey="html"
                                title="HTML"
                                className={`content_tab-button`}
                            >
                                <ResultsTabsPanel
                                    isHTMLactive={isHTMLactive}
                                    isTEXTactive={isTEXTactive}
                                    email={email}
                                    checkResults={checkResults}
                                    isLoading={isLoading}
                                    keyForResultTypes={keyForResultTypes}
                                    setKeyForResultTypes={setKeyForResultTypes} />
                            </Tab>
                            <Tab
                                eventKey="text"
                                title="TEXT"
                                className={`content_tab-button`}
                                disabled={!selectedEmail.text_result}
                            >
                                <ResultsTabsPanel
                                    isHTMLactive={isHTMLactive}
                                    isTEXTactive={isTEXTactive}
                                    email={email}
                                    checkResults={checkResults}
                                    isLoading={isLoading}
                                    keyForResultTypes={keyForResultTypes}
                                    setKeyForResultTypes={setKeyForResultTypes} />
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Accordion.Collapse>


                <section ref={inputToggle.toggleBodyRef} css={[styles.revisionSection({
                  // opened: inputToggle.opened,
                  visible: email.revisionType === 'latest' && email.oldRevisions.length > 0
                })]}>
                    {/*<pre>{JSON.stringify(email.oldRevisions, null, 2)}</pre>*/}
                    <ul css={styles.revisionList()}>
                        {email.oldRevisions?.map((oldRevision, idx) => (
                          <li key={idx}>
                              <EmailItem
                                idx={idx}
                                email={oldRevision}
                                openedEmail={openedEmail}
                                setOpenedEmail={setOpenedEmail}
                                isHTMLactive={isHTMLactive}
                                isTEXTactive={isTEXTactive}
                                handleItemClick={handleItemClick}
                                setIsHTMLActive={setIsHTMLActive}
                                setIsTEXTActive={setIsTEXTActive}
                                updateEmailsOnRead={updateEmailsOnRead}
                                isNested={true}
                              />
                          </li>
                        ))}
                    </ul>
                </section>
            </Card>
            {/* </Accordion> */}
        </>
    )
}

export default EmailItem;

function ResultsTabsPanel({ isHTMLactive, isTEXTactive, email, checkResults, isLoading, keyForResultTypes, setKeyForResultTypes }) {

    const linkTypes = [
        `ng-links-${email.id}`,
        `unpros-links-${email.id}`,
        `noeval-links-${email.id}`,
        `ok-links-${email.id}`
    ]
    const titleTypes = [
        'NG',
        '判定不能',
        '除外',
        'OK'
    ]

    // タブに表示するチェック結果の数
    const typeAmount = (list, type, idx) => {
        const originalNgNumber = list.filter(i => i.mail_format === type && i.decision === titleTypes[idx]).length
        const subtractor = list.filter(i => {
            return i.mail_format === type &&
            i.decision === titleTypes[idx] &&
            i.user_decision !== titleTypes[idx] &&
            i.user_decision !== null
        }).length
        const userAlteredNumber = originalNgNumber - subtractor

        return titleTypes[idx] === 'NG' ?
            originalNgNumber === userAlteredNumber ? `(${originalNgNumber})` : `(${originalNgNumber} → ${userAlteredNumber})`
            :
            `(${originalNgNumber})`
    }

    return (
        <>
            <div className='email-item__tabs-content border bg-body'>
                <div className={`d-flex flex-column my-2 border-0`}>

                    <Tab.Container
                        id="nav-tab-inner"
                        activeKey={keyForResultTypes}
                    >
                        <Nav variant="tabs" className='flex-row' onSelect={(k) => setKeyForResultTypes(k)}>
                            {
                                linkTypes.map((l, idx) => {
                                    return (
                                        <Nav.Item key={idx}>
                                            <Nav.Link eventKey={l}>
                                                {titleTypes[idx]} <span style={{ fontSize: 6 + 'px' }}>
                                                    {
                                                        isHTMLactive ?
                                                            typeAmount(checkResults, 'html', idx)
                                                            :
                                                            typeAmount(checkResults, 'text', idx)
                                                    }
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }

                        </Nav>
                        <Tab.Content>
                            {
                                linkTypes.map((l, idx) => {
                                    return (
                                        <Tab.Pane key={idx} eventKey={l}>
                                            <div className="py-3 border-secondary-subtle bg-white">
                                                {idx === 0 && <CheckResultItemNG checkResults={checkResults} isHTMLactive={isHTMLactive} isTEXTactive={isTEXTactive} isLoading={isLoading} />}
                                                {idx === 1 && <CheckResultItemNA checkResults={checkResults} isHTMLactive={isHTMLactive} isTEXTactive={isTEXTactive} isLoading={isLoading} />}
                                                {idx === 2 && <CheckResultItemNoEval checkResults={checkResults} isHTMLactive={isHTMLactive} isTEXTactive={isTEXTactive} isLoading={isLoading} />}
                                                {idx === 3 && <CheckResultItemOK checkResults={checkResults} isHTMLactive={isHTMLactive} isTEXTactive={isTEXTactive} isLoading={isLoading} />}
                                            </div>
                                        </Tab.Pane>)
                                })
                            }
                        </Tab.Content>
                    </Tab.Container>
                </div>

            </div>
        </>
    )
}
