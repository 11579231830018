import { css } from '@emotion/react';

export const SettingsStyles = () => ({
    main: () => css`
    .settings__nav-link {
        color: #212529;
        font-size: 11px;
        &:hover {
            color: #212529;
            background-color: #E9ECEF;
        }
    }

    .fs-8 {
        font-size: 8px;
    }
    `
})