/** @jsxImportSource @emotion/react */
import React, { RefCallback, useCallback, useEffect, useRef, useState } from 'react'
import { ReactShadowDom } from '../../utils/react-shadow-dom/ReactShadowDom'
import { emailContentBodyStyles } from './EmailContentBody.styles'
import { $EmailInfo$Client, EmailInfo } from '../../graphql/queriesIO'
import { currentCheckResultListContext } from '../../contexts/data/CheckResultListDataContext'

export const EmailContentBody = React.memo<{
  type: 'html' | 'text',
  emailInfo: $EmailInfo$Client,
  isHighlighted: boolean
}>((props) => {
  const { styles, ...store } = useEmailContentBody({ ...props })
  return (
    <div css={styles.root()}>
      <ReactShadowDom>
        <div ref={store.originalLayerRef}
          css={styles.originalShadowDom()}
          className={'emailContentBody__original'}
          dangerouslySetInnerHTML={{ __html: store.shadowDomHTML }}
        />
      </ReactShadowDom>
      {props.isHighlighted && (
        <div css={styles.overlayNormalDom()}>
          <div ref={store.overlayLayerRef} css={styles.overlayRoot()}>
            {store.numberings.map((item) => (
              <CheckpointNumber
                key={item.anchorKey}
                createCss={styles.overlayCheckpointNumber}
                {...item}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
})
EmailContentBody.displayName = 'EmailContentBody'

function useEmailContentBody(params: {
  type: 'html' | 'text',
  emailInfo: $EmailInfo$Client,
  isHighlighted: boolean
}) {
  const checkResultsContext = currentCheckResultListContext.useConsumer()
  const styles = emailContentBodyStyles({ ...params })
  const shadowDomHTML = params.type === 'html'
    ? EmailInfo.getHtmlResult(params.emailInfo)
    : EmailInfo.getTextResult(params.emailInfo)

  const overlayLayerRef = useRef<HTMLDivElement>(null)
  const shadowDomRoot = useRef<{ value?: HTMLElement | null }>({})
  const [numberings, setNumberings] = useState<Array<$CheckpointNumberProps>>([])

  const refresh = useCallback(async (callbackParams: { shadowDomRoot?: HTMLElement | null }) => {
    setNumberings([])
    checkResultsContext.clearHiddenItems()
    if (!callbackParams.shadowDomRoot) return
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 0)) // 描画調整用 Hack

    // const targetAnchors = params.shadowDomRoot.querySelectorAll<HTMLElement>('.targeturl') ?? []
    const targetAnchors = callbackParams.shadowDomRoot.querySelectorAll<HTMLElement>('[data-urlid]') ?? []
    const relativeRects = Array
      .from(targetAnchors)
      .map<$CheckpointNumberProps | undefined>((element, index) => {
        const checkResultId = element.dataset.urlid!
        const anchorKey = checkResultsContext.items.find((result) => result.id === checkResultId)?.number_in_same_mail
        if (!anchorKey) return

        element.dataset.mailCheckerCheckpointKey = anchorKey

        const elStyles = window.getComputedStyle(element)
        const elVisible = !!element.offsetParent
        // console.log('RefCallback', { index, anchorKey, display: elStyles.display, marginLeft: elStyles.marginLeft, visible: elVisible })
        if (!elVisible) {
          checkResultsContext.addHiddenItem({ checkResultId })
          return
        }

        const parentRect = callbackParams.shadowDomRoot!.getBoundingClientRect()
        const elementRect = element.getBoundingClientRect()
        const relativeRect = {
          top: elementRect.top - parentRect.top,    // + window.scrollY,
          right: elementRect.right - parentRect.right,  // + window.scrollX,
          bottom: elementRect.bottom - parentRect.bottom, // + window.scrollY,
          left: elementRect.left - parentRect.left,   // + window.scrollX,
        }
        return { anchorKey, relativeRect }
      })
      .filter((_): _ is NonNullable<typeof _> => Boolean(_))

    setNumberings(relativeRects)
  }, [checkResultsContext.items])

  const originalLayerRef = useCallback<RefCallback<HTMLElement>>((_shadowDomRoot) => {
    shadowDomRoot.current.value = _shadowDomRoot
    refresh({ shadowDomRoot: shadowDomRoot.current.value })
  }, [refresh])

  useEffect(() => {
    refresh({ shadowDomRoot: shadowDomRoot.current.value })
  }, [params.emailInfo.id, params.isHighlighted, refresh])

  return {
    styles,
    shadowDomHTML,
    originalLayerRef,
    overlayLayerRef,
    numberings,
  }
}

type $CheckpointNumberProps = {
  anchorKey: string,
  relativeRect: {
    top: number,
    right: number,
    bottom: number,
    left: number,
  }
}
const CheckpointNumber = React.memo<$CheckpointNumberProps & {
  createCss: ReturnType<typeof emailContentBodyStyles>['overlayCheckpointNumber'],
}>(({ ...props }) => {
  return (
    <div
      css={props.createCss({ ...props.relativeRect })}
      data-mail-checker-checkpoint-key={props.anchorKey}
    >
      {props.anchorKey}
    </div>
  )
})
CheckpointNumber.displayName = 'CheckpointNumber'
