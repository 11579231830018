import { css } from '@emotion/react';

export const MenuPatternsStyles = () => ({
    main: () => css`
        .menu-patterns__headers{
            display: grid;
            grid-template-columns: 48% 7% 35% 10%;
            grid-gap: 0;
            grid-auto-flow: column;
            align-items: center;
        }

        .menu-patterns__header {
            font-size: 8px;
        }
        
        .menu-patterns__title {
            font-size: 14px;
        }

        .menu-patterns__button-del {
            --bs-btn-padding-y: .25rem;
            --bs-btn-padding-x: .5rem;
            --bs-btn-font-size: 10px;
            max-width: 60px;
        }

        .add-icon {
            transition: .2s ease-in-out 0s;
        }

        .add-icon:hover {
            transform: scale(1.05);
        }
    `
})