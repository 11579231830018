/** @jsxImportSource @emotion/react */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  ChangeEventHandler
} from 'react'
import {inputCheckBoxStyles} from './InputCheckBox.styles'

export const InputCheckBox = React.memo<
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'|'onChange'> &
  {
    label: string,
    onChange?: (params: {
      event:   ChangeEvent<HTMLInputElement>,
      checked: boolean,
    }) => void,
  }
>(({ label, onChange, ...props }) => {
  const styles = inputCheckBoxStyles()
  const [checked, setChecked] = useState<boolean>(false)
  useEffect(() => setChecked(props.checked ?? false), [props.checked])

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const newChecked = event.currentTarget.checked
    setChecked(checked)
    onChange?.({
      event,
      checked: newChecked,
    })
  }, [])
  useEffect(() => {
    setChecked(props.checked ?? false)
  }, [checked])

  return (
    <label css={styles.root()}>
      <input type="checkbox" {...props} onChange={handleChange} />
      <p>{label}</p>
    </label>
  )
})
InputCheckBox.displayName = 'InputCheckBox'
