import { css } from '@emotion/react';

export const CustomDropdownStyles = () => ({
    main: () => css`
        .dropdown__toggle {
            font-size: 10px;
            background-color: #fff;
            border-color: #f0f0f0  !important;
            color: #6e7174;
            font-weight: 600;
            padding: 4px;
            margin-top: -4px;
            &:hover {
                background-color: #f0f0f0;
                color: #212529;
                border-color: #f0f0f0;
            }
            &:active,
            &:focus-visible {
                background-color: #f0f0f0 !important;
                color: #212529 !important;
                border-color: #f0f0f0 !important;
            }
        }

        .dropdown__menu {
            border-color: #f0f0f0;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
            font-size: 12px;
        }
    `,
    commentArea: () => css`
        .dropdown__form-container {
            z-index: 10000;
            width: 70%;
            height: auto;
            position: absolute;
            background-color: rgb(255 255 255 / 0.8);
            padding: 10px;
            border-radius: 15px;
            border: 1px solid  #f0f0f0;
            box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
            font-size: 11px;
        }

        .dropdown__input {
            min-height: 50px;
        }

        .dropdown__button {
            font-size: 11px;
            transition: .2s ease-in-out 0s;
            background-color: #f0f0f0;
            &:hover {
                background-color: #ced0d2;
            }
        }
    `
})