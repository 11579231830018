import { css } from '@emotion/react';

export const MenuPatternRowStyles = () => ({
    main: () => css`
        .menu-patterns__results {
            display: grid;
            grid-template-columns: 48% 7% 35% 10%;
            grid-gap: 0;
            grid-auto-flow: column;
            align-items: center;
            font-size: 7px;
        }

        .menu-patterns__button-del {
            justify-self: end;
            width: 50px;
            --bs-btn-padding-y: .25rem;
            --bs-btn-padding-x: .5rem;
            --bs-btn-font-size: .6rem;
        }

        .menu-patterns__input {
            outline: none;
            border: none;
        }
    `
})