//@ts-check
/** @jsxImportSource @emotion/react */
import React from "react";
// import './SettingsOpsBar.css';
import { SettingsOpsBarStyles } from './SettingsOpsBar.style'

function SettingsOpsBar({
    handleSubmit,
    handleDiscardChanges,
    isActive,
}) {

    const style = SettingsOpsBarStyles();

    const onsubmit = async () => {
        await handleSubmit();
    }

    const onDiscardChanges = async () => {
        await handleDiscardChanges();
    }

    return (
        <div css={style.main}>
            <div className="settings-bar d-flex flex-row border-bottom w-100">
                <button className="settings-bar__btn-save btn d-flex align-items-center my-1" type="button" onClick={onsubmit} disabled={!isActive}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="green" className="bi bi-check-square" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                    </svg>
                    <span className="settings-bar__lable ms-2">変更内容確定</span>
                </button>
                <button className="settings-bar__btn-cancel btn d-flex align-items-center my-1" type="button" onClick={onDiscardChanges}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="green" className="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>
                    <span className="settings-bar__lable ms-2">キャンセル</span>
                </button>
            </div>
        </div>
    )
}

export default SettingsOpsBar;