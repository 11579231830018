/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEmailInfo = /* GraphQL */ `
  mutation CreateEmailInfo(
    $input: CreateEmailInfoInput!
    $condition: ModelEmailInfoConditionInput
  ) {
    createEmailInfo(input: $input, condition: $condition) {
      id
      tenant
      tenantIndex
      sender
      subject
      received
      checked
      all_link
      ok_link
      ng_link
      exclude_link
      unprocessed_link
      html_body
      text_body
      html_result
      text_result
      html_result_gzip
      text_result_gzip
      archived
      read
      results {
        items {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEmailInfo = /* GraphQL */ `
  mutation UpdateEmailInfo(
    $input: UpdateEmailInfoInput!
    $condition: ModelEmailInfoConditionInput
  ) {
    updateEmailInfo(input: $input, condition: $condition) {
      id
      tenant
      tenantIndex
      sender
      subject
      received
      checked
      all_link
      ok_link
      ng_link
      exclude_link
      unprocessed_link
      html_body
      text_body
      html_result
      text_result
      html_result_gzip
      text_result_gzip
      archived
      read
      results {
        items {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmailInfo = /* GraphQL */ `
  mutation DeleteEmailInfo(
    $input: DeleteEmailInfoInput!
    $condition: ModelEmailInfoConditionInput
  ) {
    deleteEmailInfo(input: $input, condition: $condition) {
      id
      tenant
      tenantIndex
      sender
      subject
      received
      checked
      all_link
      ok_link
      ng_link
      exclude_link
      unprocessed_link
      html_body
      text_body
      html_result
      text_result
      html_result_gzip
      text_result_gzip
      archived
      read
      results {
        items {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCheckResult = /* GraphQL */ `
  mutation CreateCheckResult(
    $input: CreateCheckResultInput!
    $condition: ModelCheckResultConditionInput
  ) {
    createCheckResult(input: $input, condition: $condition) {
      id
      mail_id
      tenant
      decision
      html_tag
      mail_format
      link_text
      url
      status_code
      status_title
      status_code_description
      status_code_description_ja
      exclude_rule_name
      user_decision
      data_linkcheck_index
      exclude
      exclude_rule_pattern
      comments {
        items {
          tenant
          check_result_id
          comment_body
          changed
          editor
          previous_status
          new_status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCheckResult = /* GraphQL */ `
  mutation UpdateCheckResult(
    $input: UpdateCheckResultInput!
    $condition: ModelCheckResultConditionInput
  ) {
    updateCheckResult(input: $input, condition: $condition) {
      id
      mail_id
      tenant
      decision
      html_tag
      mail_format
      link_text
      url
      status_code
      status_title
      status_code_description
      status_code_description_ja
      exclude_rule_name
      user_decision
      data_linkcheck_index
      exclude
      exclude_rule_pattern
      comments {
        items {
          tenant
          check_result_id
          comment_body
          changed
          editor
          previous_status
          new_status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCheckResult = /* GraphQL */ `
  mutation DeleteCheckResult(
    $input: DeleteCheckResultInput!
    $condition: ModelCheckResultConditionInput
  ) {
    deleteCheckResult(input: $input, condition: $condition) {
      id
      mail_id
      tenant
      decision
      html_tag
      mail_format
      link_text
      url
      status_code
      status_title
      status_code_description
      status_code_description_ja
      exclude_rule_name
      user_decision
      data_linkcheck_index
      exclude
      exclude_rule_pattern
      comments {
        items {
          tenant
          check_result_id
          comment_body
          changed
          editor
          previous_status
          new_status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      tenant
      check_result_id
      comment_body
      changed
      editor
      previous_status
      new_status
      check_result {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      tenant
      check_result_id
      comment_body
      changed
      editor
      previous_status
      new_status
      check_result {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      tenant
      check_result_id
      comment_body
      changed
      editor
      previous_status
      new_status
      check_result {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createTenantConfig = /* GraphQL */ `
  mutation CreateTenantConfig(
    $input: CreateTenantConfigInput!
    $condition: ModelTenantConfigConditionInput
  ) {
    createTenantConfig(input: $input, condition: $condition) {
      tenant
      email_for_check
      notification_to {
        tenant
        email_address
        id
        createdAt
        updatedAt
      }
      specific_pattern {
        tenant
        name
        type
        pattern
        enabled
        id
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateTenantConfig = /* GraphQL */ `
  mutation UpdateTenantConfig(
    $input: UpdateTenantConfigInput!
    $condition: ModelTenantConfigConditionInput
  ) {
    updateTenantConfig(input: $input, condition: $condition) {
      tenant
      email_for_check
      notification_to {
        tenant
        email_address
        id
        createdAt
        updatedAt
      }
      specific_pattern {
        tenant
        name
        type
        pattern
        enabled
        id
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenantConfig = /* GraphQL */ `
  mutation DeleteTenantConfig(
    $input: DeleteTenantConfigInput!
    $condition: ModelTenantConfigConditionInput
  ) {
    deleteTenantConfig(input: $input, condition: $condition) {
      tenant
      email_for_check
      notification_to {
        tenant
        email_address
        id
        createdAt
        updatedAt
      }
      specific_pattern {
        tenant
        name
        type
        pattern
        enabled
        id
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createAdditionalMailAddress = /* GraphQL */ `
  mutation CreateAdditionalMailAddress(
    $input: CreateAdditionalMailAddressInput!
    $condition: ModelAdditionalMailAddressConditionInput
  ) {
    createAdditionalMailAddress(input: $input, condition: $condition) {
      tenant
      email_address
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateAdditionalMailAddress = /* GraphQL */ `
  mutation UpdateAdditionalMailAddress(
    $input: UpdateAdditionalMailAddressInput!
    $condition: ModelAdditionalMailAddressConditionInput
  ) {
    updateAdditionalMailAddress(input: $input, condition: $condition) {
      tenant
      email_address
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdditionalMailAddress = /* GraphQL */ `
  mutation DeleteAdditionalMailAddress(
    $input: DeleteAdditionalMailAddressInput!
    $condition: ModelAdditionalMailAddressConditionInput
  ) {
    deleteAdditionalMailAddress(input: $input, condition: $condition) {
      tenant
      email_address
      id
      createdAt
      updatedAt
    }
  }
`;
export const createSpecificPattern = /* GraphQL */ `
  mutation CreateSpecificPattern(
    $input: CreateSpecificPatternInput!
    $condition: ModelSpecificPatternConditionInput
  ) {
    createSpecificPattern(input: $input, condition: $condition) {
      tenant
      name
      type
      pattern
      enabled
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateSpecificPattern = /* GraphQL */ `
  mutation UpdateSpecificPattern(
    $input: UpdateSpecificPatternInput!
    $condition: ModelSpecificPatternConditionInput
  ) {
    updateSpecificPattern(input: $input, condition: $condition) {
      tenant
      name
      type
      pattern
      enabled
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpecificPattern = /* GraphQL */ `
  mutation DeleteSpecificPattern(
    $input: DeleteSpecificPatternInput!
    $condition: ModelSpecificPatternConditionInput
  ) {
    deleteSpecificPattern(input: $input, condition: $condition) {
      tenant
      name
      type
      pattern
      enabled
      id
      createdAt
      updatedAt
    }
  }
`;
