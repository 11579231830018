//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { css } from '@emotion/react';
import { useLocation } from "react-router-dom";
import EmailItem from '../EmailItem/EmailItem';
// import './EmailList.css';
import { EmailsListStyle } from './EmailList.styles';
// Bootstrap
import Accordion from 'react-bootstrap/Accordion';
import Spinner from 'react-bootstrap/Spinner';
import { archiveTargetListDataContext } from '../../contexts/data/ArchiveTargetListDataContext'

function EmailList({
    emailList,
    handleItemClick,
    isHTMLactive,
    isTEXTactive,
    refreshKey,
    setIsTEXTActive,
    setIsHTMLActive,
    updateEmailsOnRead,
    isEmailInfoLoading
}) {
    const archiveTargetList = archiveTargetListDataContext.useConsumer()

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [openedEmail, setOpenedEmail] = useState({});
    const pathname = useLocation().pathname;
    const styles = EmailsListStyle();

    // currentUserが送信したメールを絞る
    // const myEmailList = emailList.filter(e => e.sender === JSON.parse(localStorage.getItem('user') || '{}').email);
    // const myEmailList = emailList.filter(e => e.sender === 'マーケティング太郎 <glocalmllllllllllllllllllllllllllllllkkkkkkka@testmail.com>');
    // console.log('myEmailList: ',myEmailList)

    // const handleTabClick = (e) => {
    //     if (e.target.id === 'nav-html-tab') {
    //         setIsHTMLActive(true);
    //         setIsTEXTActive(false);
    //     } if (e.target.id === 'nav-text-tab' && e.target.disabled === false) {
    //         setIsHTMLActive(false);
    //         setIsTEXTActive(true);
    //     }
    // }

    // const changeOpenedEmail = (email) => {
    //     setOpenedEmail(email)
    // }

    const handleCheckAll = e => {
        archiveTargetList.toggleList(emailList)
    };


    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setScreenWidth(e.target.innerWidth);
        })
    });

    useEffect(() => {
        setOpenedEmail({})
        archiveTargetList.setItems([])
    }, [refreshKey])

    return !isEmailInfoLoading ? (
        <Accordion
            defaultActiveKey="0"
            css={styles.container}
            className={`d-flex flex-column ${screenWidth < 992 ? 'w-100 h-50' : 'w-50 h-100'} bg-secondary-subtle pb-2`}
        >
            {/* <div className={`req-list d-flex flex-column ${screenWidth < 992 ? 'w-100 h-50' : 'w-50 h-100'} bg-secondary-subtle`} id={`accordionExample`}> */}
            <div css={styles.headerGrid} className="py-2 bg-white sticky-top border-bottom shadow-sm px-2">
                <span className="ps-3">
                    <input
                        className="email-list__checkbox-header"
                        type="checkbox"
                        checked={archiveTargetList.isCheckedAll(emailList)}
                        onChange={handleCheckAll} />
                </span>
                <span className="req-list_header"></span>
                <span className="req-list_header">From</span>
                <span className="req-list_header">サマリー</span>
                <span className="req-list_header">Received</span>
                <span className="req-list_header"></span>
            </div>
            {
                emailList.length > 0 ?
                    emailList.map((email, idx) => {
                        const { id, archived } = email;
                        return <EmailItem
                            // key={`${refreshKey}shared${email.id}`}
                            key={`${pathname === '/' ? refreshKey + 'shared' + id : refreshKey + 'my' + id}`}
                            idx={idx}
                            email={email}
                            openedEmail={openedEmail}
                            setOpenedEmail={setOpenedEmail}
                            isHTMLactive={isHTMLactive}
                            isTEXTactive={isTEXTactive}
                            handleItemClick={handleItemClick}
                            setIsHTMLActive={setIsHTMLActive}
                            setIsTEXTActive={setIsTEXTActive}
                            updateEmailsOnRead={updateEmailsOnRead}
                            isNested={false}
                        />
                    })
                    :
                    <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-light text-body-tertiary fs-4">表示できるメールがありません。</p>
                    </div>
            }
            {/* </div> */}
        </Accordion>
    )
        :
        (
            <div 
                className="d-flex justify-content-center align-items-start"
                css={css`
                    margin: 50px auto;
                `}>
                <Spinner animation="grow" role="status" variant="secondary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )
}

export default EmailList;