//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Paginate from '../utils/Paginate/Paginate';
import {CheckResultsGridRowBody, CheckResultsGridRowHead} from './CheckResultsGrid'

import {CheckResultItemStyles} from './CheckResultItem.style';

function CheckResultItemNoEval({ checkResults, isHTMLactive, isTEXTactive, isLoading }) {

    const resultsHTML = checkResults.filter(result => result.decision === "除外" && result.mail_format === "html");
    const resultsTEXT = checkResults.filter(result => result.decision === "除外" && result.mail_format === "text");

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentHtmlItems = resultsHTML.slice(indexOfFirstItem, indexOfLastItem);
    const currentTextItems = resultsTEXT.slice(indexOfFirstItem, indexOfLastItem);

    const style = CheckResultItemStyles();

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (isHTMLactive) {
            if (currentPage !== Math.ceil(resultsHTML.length / itemsPerPage)) {
                setCurrentPage(currentPage + 1);
            }
        }
        if (isTEXTactive) {
            if (currentPage !== Math.ceil(resultsTEXT.length / itemsPerPage)) {
                setCurrentPage(currentPage + 1);
            }
        }
    };

    useEffect(() => {
        setCurrentPage(1)
    }, [isTEXTactive, isHTMLactive])

    return (
        <div css={style.main} className={`check-result-item d-flex flex-column bg-body shadow-sm my-1 mx-auto border-bottom`} style={{ width: 98 + '%' }}>
            <div className='check-result-item__title-area d-flex justify-content-end flex-row pb-2 pe-2'>
                
                <Paginate
                    itemsPerPage={itemsPerPage}
                    totalItems={isHTMLactive ? resultsHTML.length : resultsTEXT.length}
                    paginate={paginate}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    currentPage={currentPage}
                    fontSize={10} />
            </div>
            <CheckResultsGridRowHead variant={'NoEval'} />
            {
                !isLoading ?
                    (resultsHTML.length > 0 ?
                        isHTMLactive &&
                        currentHtmlItems.map((result, idx) => {
                            return (
                              <CheckResultsGridRowBody key={idx} variant={'Data$Uncheckable'} record={result} />
                            )
                        })
                        :
                        (isHTMLactive &&
                          <CheckResultsGridRowBody variant={'Empty$Uncheckable'} />))
                    :
                    isHTMLactive &&
                    checkResults.length === 0 &&
                    (<CheckResultsGridRowBody variant={'Skeleton$Uncheckable'} subType={'usePlaceholderLg'} />)
            }
            {
                !isLoading ?
                    (resultsTEXT.length > 0 ?
                        isTEXTactive &&
                        currentTextItems.map((result, idx) => {
                            return (
                              <CheckResultsGridRowBody key={idx} variant={'Data$Uncheckable'} record={result} />
                            )
                        })
                        :
                        (isTEXTactive &&
                          <CheckResultsGridRowBody variant={'Empty$Uncheckable'} />)
                    )
                    :
                    isTEXTactive &&
                    (<CheckResultsGridRowBody variant={'Skeleton$Uncheckable'} subType={'useFontSize'} />)
            }
        </div>
    )
}

export default CheckResultItemNoEval;