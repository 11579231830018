import { css } from '@emotion/react';

export const CheckResultItemsStyles = () => ({
    header: (headerNum: 1 | 2) => css`
        display: grid;
        grid-template-columns: ${headerNum === 1 ? '6% 16.25% 6.25% 12.5% 23% 9.5% 7.75% 18.75%;' : '6.25% 6.25% 17.5% 30% 21.25% 18.75%;' }
        grid-gap: 0;
        grid-auto-flow: column;
        overflow-wrap: anywhere;
        font-size: 7px;
    `,
    body: (headerNum: 1 | 2) => css `
        display: grid;
        grid-template-columns: ${headerNum === 1 ? '6% 16.25% 6.25% 12.5% 23% 9.5% 7.75% 18.75%;' : '6.25% 6.25% 17.5% 30% 21.25% 18.75%;' }
        grid-gap: 0;
        grid-auto-flow: column;
        overflow-wrap: anywhere;
        font-size: 7px;
    `,
    textBold: () => css`
        font-weight: bold;
    `,
})