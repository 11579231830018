//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from "react";
// import './SearchBar.css';
import { SearchBarStyles } from './SearchBar.styles'
import Paginate from "../utils/Paginate/Paginate";
import CustomToast from "../utils/Toast/Toast";
import { tooltipMessages } from "../../utils/tooltopMessages";

// Bootstrap
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useEmailInfoListDataContext } from '../../contexts/data/EmailInfoListDataContext'
import { archiveTargetListDataContext } from '../../contexts/data/ArchiveTargetListDataContext'
import { InputCheckBox } from '../input/InputCheckBox'
import { useLocation } from "react-router-dom";
// import Toast from 'react-bootstrap/Toast';

function SearchBar({
    handleNGFilter,
    isNGButtonActive,
    handleUnprocessedFilter,
    isUnprocessedButtonActive,
    emailsPerPage,
    totalEmails,
    paginate,
    nextPage,
    previousPage,
    currentPage,
    isEmailInfoLoading,
    isLoadingOnArchived,
    setIsLoadingOnArchived,
    setIsEmailInfoLoading,
    handleRefresh,
    updateEmailsOnArchived,
    disableSenderFilter,
    isNextToken,
}) {
    const emailInfoListDataContext = useEmailInfoListDataContext()
    const archiveTargetList = archiveTargetListDataContext.useConsumer()

    const styles = SearchBarStyles();
    const pathname = useLocation().pathname;

    // Modal用 //
    // ↓
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => {
        if (archiveTargetList.items.length !== 0) {
            setShowModal(true);
        }

    }
    // ↑

    // const pathname = useLocation().pathname;
    // const archiveTargetList = archiveTargetListDataContext.useConsumer()
    useEffect(() => { archiveTargetList.setIsToArchive(pathname === '/archive' ? false : true) }, [archiveTargetList.items])

    // Toast用
    // ↓
    const [showToast, setShowToast] = useState(false);
    const toggleShowToast = () => setShowToast(!showToast);

    // ↑

    const onNGFilter = () => {
        handleNGFilter();
    }

    const handleRefreshRequest = async () => {
        await handleRefresh();
        // setIsEmailInfoLoading(false);
    }

    const onRefresh = async () => {
        await setIsEmailInfoLoading(true);
        await handleRefreshRequest();
    }

    const handleArchiveSubmit = async () => {
        try {
            setIsLoadingOnArchived(true)
            await archiveTargetList.submitList(async (item) => {
                // TODO: あまり綺麗じゃない
                await updateEmailsOnArchived(item)
            })
        } catch (error) {
            console.log(error);
        }
    }

    const onArchiveSubmit = async () => {
        try {
            await handleArchiveSubmit();
            // await onRefresh() // update後更新されたEmailInfoをフェッチする
            setIsLoadingOnArchived(false);
            await handleClose();
            toggleShowToast();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="fs-5">{ pathname === '/archive' ? '再表示' : '非表示'}</Modal.Title>
                </Modal.Header>
                <Modal.Body css={styles.fontSize12}><strong>{archiveTargetList.items.length}</strong>{`件のメールを${pathname === '/archive' ? '再表示' : '非表示に'}しますか？`}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" css={styles.fontSize12} onClick={handleClose}>
                        キャンセル
                    </Button>
                    <Button variant="success" css={styles.fontSize12} onClick={onArchiveSubmit}>
                        { pathname === '/archive' ? '再表示' : '非表示'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <CustomToast
                showToast={showToast}
                toastMessage={ pathname === '/archive' ? '選択されたメールを再表示しました。' :'選択されたメールを非表示にしました。'}
                toggleShowToast={toggleShowToast} />


            <div css={styles.searchBar} className="border-bottom border-dark-subtle py-2 ps-1 d-flex flex-row justify-content-between bg-white sticky-top">
                <div className="d-flex flex-row justify-content-start">
                    <div className="input-group rounded ms-1 me-1">

                        <OverlayTrigger
                            placement='bottom'
                            rootClose={true}
                            overlay={
                                <Tooltip id={`tooltip-refresh`}>
                                    リフレッシュ
                                </Tooltip>
                            }
                        >
                            <Button
                                type="button"
                                variant="none"
                                disabled={isNextToken}
                                css={[styles.searchBarBtns, styles.searchBarBtnsHover(false)]}
                                className="h-100 rounded border-0 me-2 d-flex align-items-center"
                                onClick={onRefresh}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                                </svg>
                            </Button>
                        </OverlayTrigger>

                        <Form.Control
                            type="search"
                            className="h-100 rounded"
                            size="sm"
                            placeholder="件名検索"
                            onChange={(e) => {
                                emailInfoListDataContext.setFilterSubject(e.target.value)
                                archiveTargetList.setItems([])
                            }}
                            value={emailInfoListDataContext.filters.subject}
                        />

                        {!disableSenderFilter &&
                            <Form.Control
                                type="search"
                                className="h-100 rounded ms-2"
                                size="sm"
                                placeholder="from検索"
                                onChange={(e) => {
                                    emailInfoListDataContext.setFilterSender(e.target.value)
                                    archiveTargetList.setItems([])
                                }}
                                value={emailInfoListDataContext.filters.sender}
                            />
                        }

                        <OverlayTrigger
                            placement='bottom'
                            rootClose={true}
                            overlay={
                                <Tooltip id={`tooltip-ng`}>
                                    NG結果のみを表示する
                                </Tooltip>
                            }
                        >

                            <Button
                                type="button"
                                variant="none"
                                css={[styles.searchBarBtns, styles.searchBarBtnsHover(true)]}
                                className={`h-100 rounded border-0 ms-2 ${isNGButtonActive ? 'bg-danger-subtle' : ''} d-flex align-items-center`}
                                onClick={onNGFilter}
                            >
                                NG
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement='bottom'
                            rootClose={true}
                            overlay={
                                <Tooltip id={`tooltip-ng`}>
                                    判定不能結果のみを表示する
                                </Tooltip>
                            }
                        >

                            <Button
                                type="button"
                                variant="none"
                                css={[styles.searchBarBtns, styles.searchBarBtnsHover(true)]}
                                className={`h-100 rounded border-0 ms-2 ${isUnprocessedButtonActive ? 'bg-danger-subtle' : ''} d-flex align-items-center`}
                                onClick={handleUnprocessedFilter}
                            >
                                判定不能
                            </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement='bottom'
                            rootClose={true}
                            overlay={
                                <Tooltip id={`tooltip-archive`}>
                                    { pathname === '/archive' ? '再表示する' : '非表示にする'}
                                </Tooltip>
                            }
                        >

                            <Button
                                type="button"
                                variant="none"
                                disabled={archiveTargetList.items.length === 0}
                                css={[styles.searchBarBtns, styles.searchBarBtnsHover(false)]}
                                className={`h-100 d-flex align-items-center rounded border-0 ms-2`}
                                onClick={handleShow}
                            >
                                {
                                    isLoadingOnArchived ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden"></span>
                                        </div>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-archive-fill" viewBox="0 0 16 16">
                                            <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z" />
                                        </svg>
                                }
                            </Button>
                        </OverlayTrigger>

                    </div>

                    <Paginate
                        itemsPerPage={emailsPerPage}
                        totalItems={totalEmails}
                        paginate={paginate}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        currentPage={currentPage}
                        fontSize={16} />

                    <div css={styles.toggleThreadMode()}>
                        <InputCheckBox
                            label={'スレッド表示'}
                            checked={emailInfoListDataContext.enabledThreadMode}
                            onChange={({ checked }) => {
                                console.log({ checked })
                                emailInfoListDataContext.setEnabledThreadMode(checked)
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchBar;