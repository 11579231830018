/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEmailInfo = /* GraphQL */ `
  query GetEmailInfo($id: ID!) {
    getEmailInfo(id: $id) {
      id
      tenant
      tenantIndex
      sender
      subject
      received
      checked
      all_link
      ok_link
      ng_link
      exclude_link
      unprocessed_link
      html_body
      text_body
      html_result
      text_result
      html_result_gzip
      text_result_gzip
      archived
      read
      results {
        items {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEmailInfos = /* GraphQL */ `
  query ListEmailInfos(
    $id: ID
    $filter: ModelEmailInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmailInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenant
        tenantIndex
        sender
        subject
        received
        checked
        all_link
        ok_link
        ng_link
        exclude_link
        unprocessed_link
        html_body
        text_body
        html_result
        text_result
        html_result_gzip
        text_result_gzip
        archived
        read
        results {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCheckResult = /* GraphQL */ `
  query GetCheckResult($id: ID!) {
    getCheckResult(id: $id) {
      id
      mail_id
      tenant
      decision
      html_tag
      mail_format
      link_text
      url
      status_code
      status_title
      status_code_description
      status_code_description_ja
      exclude_rule_name
      user_decision
      data_linkcheck_index
      exclude
      exclude_rule_pattern
      comments {
        items {
          tenant
          check_result_id
          comment_body
          changed
          editor
          previous_status
          new_status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCheckResults = /* GraphQL */ `
  query ListCheckResults(
    $id: ID
    $filter: ModelCheckResultFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCheckResults(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      tenant
      check_result_id
      comment_body
      changed
      editor
      previous_status
      new_status
      check_result {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tenant
        check_result_id
        comment_body
        changed
        editor
        previous_status
        new_status
        check_result {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenantConfig = /* GraphQL */ `
  query GetTenantConfig($id: ID!) {
    getTenantConfig(id: $id) {
      tenant
      email_for_check
      notification_to {
        tenant
        email_address
        id
        createdAt
        updatedAt
      }
      specific_pattern {
        tenant
        name
        type
        pattern
        enabled
        id
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listTenantConfigs = /* GraphQL */ `
  query ListTenantConfigs(
    $filter: ModelTenantConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tenant
        email_for_check
        notification_to {
          tenant
          email_address
          id
          createdAt
          updatedAt
        }
        specific_pattern {
          tenant
          name
          type
          pattern
          enabled
          id
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdditionalMailAddress = /* GraphQL */ `
  query GetAdditionalMailAddress($id: ID!) {
    getAdditionalMailAddress(id: $id) {
      tenant
      email_address
      id
      createdAt
      updatedAt
    }
  }
`;
export const listAdditionalMailAddresses = /* GraphQL */ `
  query ListAdditionalMailAddresses(
    $filter: ModelAdditionalMailAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalMailAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenant
        email_address
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpecificPattern = /* GraphQL */ `
  query GetSpecificPattern($id: ID!) {
    getSpecificPattern(id: $id) {
      tenant
      name
      type
      pattern
      enabled
      id
      createdAt
      updatedAt
    }
  }
`;
export const listSpecificPatterns = /* GraphQL */ `
  query ListSpecificPatterns(
    $filter: ModelSpecificPatternFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecificPatterns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenant
        name
        type
        pattern
        enabled
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const emailInfosByTenantIndexAndReceived = /* GraphQL */ `
  query EmailInfosByTenantIndexAndReceived(
    $tenantIndex: String!
    $received: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmailInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailInfosByTenantIndexAndReceived(
      tenantIndex: $tenantIndex
      received: $received
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        tenantIndex
        sender
        subject
        received
        checked
        all_link
        ok_link
        ng_link
        exclude_link
        unprocessed_link
        html_body
        text_body
        html_result
        text_result
        html_result_gzip
        text_result_gzip
        archived
        read
        results {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCheckResultsByMailId = /* GraphQL */ `
  query ListCheckResultsByMailId(
    $mail_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckResultsByMailId(
      mail_id: $mail_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          items {
            tenant
            check_result_id
            comment_body
            changed
            editor
            previous_status
            new_status
            id
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const commentsByCheck_result_idAndChanged = /* GraphQL */ `
  query CommentsByCheck_result_idAndChanged(
    $check_result_id: ID!
    $changed: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByCheck_result_idAndChanged(
      check_result_id: $check_result_id
      changed: $changed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenant
        check_result_id
        comment_body
        changed
        editor
        previous_status
        new_status
        check_result {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const additionalMailAddressesByTenant = /* GraphQL */ `
  query AdditionalMailAddressesByTenant(
    $tenant: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdditionalMailAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    additionalMailAddressesByTenant(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenant
        email_address
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const specificPatternsByTenant = /* GraphQL */ `
  query SpecificPatternsByTenant(
    $tenant: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpecificPatternFilterInput
    $limit: Int
    $nextToken: String
  ) {
    specificPatternsByTenant(
      tenant: $tenant
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenant
        name
        type
        pattern
        enabled
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
