/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateEmailInfo = /* GraphQL */ `
  subscription OnCreateEmailInfo(
    $filter: ModelSubscriptionEmailInfoFilterInput
  ) {
    onCreateEmailInfo(filter: $filter) {
      id
      tenant
      tenantIndex
      sender
      subject
      received
      checked
      all_link
      ok_link
      ng_link
      exclude_link
      unprocessed_link
      html_body
      text_body
      html_result
      text_result
      html_result_gzip
      text_result_gzip
      archived
      read
      results {
        items {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmailInfo = /* GraphQL */ `
  subscription OnUpdateEmailInfo(
    $filter: ModelSubscriptionEmailInfoFilterInput
  ) {
    onUpdateEmailInfo(filter: $filter) {
      id
      tenant
      tenantIndex
      sender
      subject
      received
      checked
      all_link
      ok_link
      ng_link
      exclude_link
      unprocessed_link
      html_body
      text_body
      html_result
      text_result
      html_result_gzip
      text_result_gzip
      archived
      read
      results {
        items {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmailInfo = /* GraphQL */ `
  subscription OnDeleteEmailInfo(
    $filter: ModelSubscriptionEmailInfoFilterInput
  ) {
    onDeleteEmailInfo(filter: $filter) {
      id
      tenant
      tenantIndex
      sender
      subject
      received
      checked
      all_link
      ok_link
      ng_link
      exclude_link
      unprocessed_link
      html_body
      text_body
      html_result
      text_result
      html_result_gzip
      text_result_gzip
      archived
      read
      results {
        items {
          id
          mail_id
          tenant
          decision
          html_tag
          mail_format
          link_text
          url
          status_code
          status_title
          status_code_description
          status_code_description_ja
          exclude_rule_name
          user_decision
          data_linkcheck_index
          exclude
          exclude_rule_pattern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCheckResult = /* GraphQL */ `
  subscription OnCreateCheckResult(
    $filter: ModelSubscriptionCheckResultFilterInput
  ) {
    onCreateCheckResult(filter: $filter) {
      id
      mail_id
      tenant
      decision
      html_tag
      mail_format
      link_text
      url
      status_code
      status_title
      status_code_description
      status_code_description_ja
      exclude_rule_name
      user_decision
      data_linkcheck_index
      exclude
      exclude_rule_pattern
      comments {
        items {
          tenant
          check_result_id
          comment_body
          changed
          editor
          previous_status
          new_status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCheckResult = /* GraphQL */ `
  subscription OnUpdateCheckResult(
    $filter: ModelSubscriptionCheckResultFilterInput
  ) {
    onUpdateCheckResult(filter: $filter) {
      id
      mail_id
      tenant
      decision
      html_tag
      mail_format
      link_text
      url
      status_code
      status_title
      status_code_description
      status_code_description_ja
      exclude_rule_name
      user_decision
      data_linkcheck_index
      exclude
      exclude_rule_pattern
      comments {
        items {
          tenant
          check_result_id
          comment_body
          changed
          editor
          previous_status
          new_status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCheckResult = /* GraphQL */ `
  subscription OnDeleteCheckResult(
    $filter: ModelSubscriptionCheckResultFilterInput
  ) {
    onDeleteCheckResult(filter: $filter) {
      id
      mail_id
      tenant
      decision
      html_tag
      mail_format
      link_text
      url
      status_code
      status_title
      status_code_description
      status_code_description_ja
      exclude_rule_name
      user_decision
      data_linkcheck_index
      exclude
      exclude_rule_pattern
      comments {
        items {
          tenant
          check_result_id
          comment_body
          changed
          editor
          previous_status
          new_status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      tenant
      check_result_id
      comment_body
      changed
      editor
      previous_status
      new_status
      check_result {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
    onUpdateComment(filter: $filter) {
      tenant
      check_result_id
      comment_body
      changed
      editor
      previous_status
      new_status
      check_result {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
    onDeleteComment(filter: $filter) {
      tenant
      check_result_id
      comment_body
      changed
      editor
      previous_status
      new_status
      check_result {
        id
        mail_id
        tenant
        decision
        html_tag
        mail_format
        link_text
        url
        status_code
        status_title
        status_code_description
        status_code_description_ja
        exclude_rule_name
        user_decision
        data_linkcheck_index
        exclude
        exclude_rule_pattern
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
