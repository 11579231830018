//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
// import './Container.css';
import EmailList from '../EmailList/EmailList';
import EmailContent from '../EmailContent/EmailContent';
import Toast from 'react-bootstrap/Toast';


function Container({
    emailList,
    // filteredList,
    refreshKey,
    handleItemClick,
    updateEmailsOnRead,
    isEmailInfoLoading, }) {

    console.log('emails in container: ', emailList)

    const [isHTMLactive, setIsHTMLActive] = useState(true);
    const [isTEXTactive, setIsTEXTActive] = useState(false);

    return (
        <div
            css={css`
            max-height: calc(100% - 80px - 50px);
        `}
            className='d-flex flex-lg-row flex-column justify-content-between align-items-stretch h-100'>
            <EmailList
                emailList={emailList}
                refreshKey={refreshKey}
                isHTMLactive={isHTMLactive}
                isTEXTactive={isTEXTactive}
                handleItemClick={handleItemClick}
                updateEmailsOnRead={updateEmailsOnRead}
                setIsHTMLActive={setIsHTMLActive}
                setIsTEXTActive={setIsTEXTActive}
                isEmailInfoLoading={isEmailInfoLoading} />
            <EmailContent
                isHTMLactive={isHTMLactive}
                isTEXTactive={isTEXTactive}
                setIsHTMLActive={setIsHTMLActive}
                setIsTEXTActive={setIsTEXTActive} />
            {/* <button type="button" className="btn btn-primary" id="liveToastBtn">Show live toast</button> */}
            {/* 
            <Toast>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Bootstrap</strong>
                    <small>11 mins ago</small>
                </Toast.Header>
                <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
            </Toast> */}
        </div>
    )
}

export default Container;