//@ts-check
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from "react";
// import './EmailContent.css';
import { EmailContentStyles } from './EmailContent.style';

// bootstrap
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {EmailContentBody} from './EmailContentBody'
import {currentEmailInfoItemContext} from '../../contexts/data/EmailInfoItemDataContext'

function EmailContent({
    isHTMLactive,
    isTEXTactive,
    setIsHTMLActive,
    setIsTEXTActive,
}) {
    const currentEmailInfo = currentEmailInfoItemContext.useConsumer()
    const selectedEmail = currentEmailInfo.item

    // {ReactHtmlParser(selectedEmail.html_body)}
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isHighlighted, setIsHighlighted] = useState(true);  // NGのURLをハイライトするかどうか；
    // const shadowRoot1 = useRef()
    // const shadowRoot2 = useRef()
    const style = EmailContentStyles()

    // Tabs用
    // ↓
    const [keyForMailType, setKeyForMailType] = useState('html');

    const handleMailTabSelect = (k) => {
        setKeyForMailType(k);
        if (k === 'html') {
            setIsHTMLActive(true);
            setIsTEXTActive(false);
        } else if (k === 'text') {
            setIsHTMLActive(false);
            setIsTEXTActive(true);
        }
    }
    // ↑

    // const sheetVisible = new CSSStyleSheet();
    // sheetVisible.replaceSync(".targeturl {position: relative;} .targeturl::before {content: ''; position: absolute; top: 0;bottom: 0;left: 0;right: 0;background-color: red;opacity: 0.5;z-index: 1;} @keyframes disappearing {0% {background-color: #ff8d8d75;}50% {background-color: transparent;}100% {background-color: #ff8d8d75;}}");
    // sheetVisible.replaceSync(".targeturl {outline: auto; outline-color: #f96e6e;background-color:#ff8989; animation:disappearing 3s linear infinite;} @keyframes disappearing {0% {outline-color: #f96e6e;background-color: #ff8989;}50% {outline-color: transparent;background-color:transparent;}100% {outline-color: #f96e6e;background-color:#ff8989;}}");

    // const sheetInvisible = new CSSStyleSheet();
    // sheetInvisible.replaceSync(".targeturl { outline: none }")
    //document.adoptedStyleSheets = [sheet];

    // const handleTabClick = (e) => {
    //     if (e.target.id === 'nav-html-tab') {
    //         setIsHTMLActive(true);
    //         setIsTEXTActive(false);
    //     } if (e.target.id === 'nav-text-tab' && e.target.disabled === false) {
    //         setIsHTMLActive(false);
    //         setIsTEXTActive(true);
    //     }
    // }

    const handleCheck = (e) => {
        setIsHighlighted(!isHighlighted)

        // toggleStyle()
    }


    // const createShadowDom = () => {
    //     const shadowDomRootElement1 = document.querySelector('.shadow1');
    //     const shadowRootElement1 = shadowDomRootElement1.attachShadow({ mode: 'open' });
    //     const div1 = document.createElement('div');
    //     shadowRoot1.current = div1;
    //     shadowRootElement1.appendChild(shadowRoot1.current);
    //     // shadowRootElement1.adoptedStyleSheets = [sheetVisible];
    //
    //     const shadowDomRootElement2 = document.querySelector('.shadow2');
    //     const shadowRootElement2 = shadowDomRootElement2.attachShadow({ mode: 'open' });
    //     const div2 = document.createElement('div');
    //     shadowRoot2.current = div2;
    //     shadowRootElement2.appendChild(shadowRoot2.current);
    //     // shadowRootElement2.adoptedStyleSheets = [sheetVisible];
    // }
    //
    // const updateShadowDom = () => {
    //     while (!!shadowRoot1.current.firstChild) {
    //         shadowRoot1.current.removeChild(shadowRoot1.current.firstChild)
    //     }
    //     shadowRoot1.current.innerHTML = selectedEmail.html_result || "";
    //     while (!!shadowRoot2.current.firstChild) {
    //         shadowRoot2.current.removeChild(shadowRoot2.current.firstChild)
    //     }
    //     shadowRoot2.current.innerHTML = selectedEmail.text_result || "";
    //     // shadowRoot1.current.adoptedStyleSheets = [style]
    //     // shadowRoot2.current.adoptedStyleSheets = [style]
    //     const elementsHtml = shadowRoot1.current.querySelectorAll('.targeturl');
    //     const elementsText = shadowRoot2.current.querySelectorAll('.targeturl');
    //     elementsHtml && elementsHtml.forEach((el) => {
    //         el.style.outline = isHighlighted ? 'rgb(249, 110, 110) auto' : 'none';
    //         el.style.outlineColor = isHighlighted ? '#f96e6e' : '';
    //         el.style.backgroundColor = isHighlighted ? '#ff8989' : 'transparent';
    //         el.style.animation = isHighlighted ? 'disappearing 3s linear infinite' : '';
    //     })
    //     elementsText && elementsText.forEach((el) => {
    //         el.style.outline = isHighlighted ? 'rgb(249, 110, 110) auto' : 'none';
    //         el.style.outlineColor = isHighlighted ? '#f96e6e' : '';
    //         el.style.backgroundColor = isHighlighted ? '#ff8989' : 'transparent';
    //         el.style.animation = isHighlighted ? 'disappearing 3s linear infinite' : '';
    //     })
    // }

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setScreenWidth(e.target.innerWidth);
        })
    });

    // useEffect(() => {
    //     createShadowDom()
    //     console.log(isHighlighted)
    // }, [])
    // useEffect(() => {
    //     updateShadowDom()
    // }, [selectedEmail, isHighlighted])


    return (
        <div css={[style.container, style.main]} className={`email-content border-start ${(screenWidth < 992) ? 'w-100 h-50' : 'w-50 h-100'} d-flex flex-column`}>
            <div className={`email-content-checkbox-contaner sticky-top bg-white shadow-sm d-flex flex-row align-items-center py-3 px-0`}>
                {
                    selectedEmail.id &&
                    <>
                        <input
                            type="checkbox"
                            id="highlight-checkbox"
                            className="email-content__checkbox ms-3"
                            checked={isHighlighted}
                            onChange={handleCheck} />
                        <label htmlFor="highlight-checkbox" className="email-content__checkbox-lable ms-1">NGのURLをハイライトする</label>
                    </>
                }
            </div>
            <div className="email-content_title-area p-2">
                <div className="email-content__infos d-flex flex-row justify-content-between">
                    <div className="email-content__email">{selectedEmail.sender || ''}</div>
                    <div className="email-content__time">{selectedEmail.createdAt && selectedEmail.createdAt.substring(0, 10) + " " + selectedEmail.createdAt.substring(11, 19)}</div>
                </div>
                <h2 className="email-content__title fs-5 text mt-3 mb-5 text-light-emphasis">{selectedEmail.subject || '件名'}</h2>
            </div>
            <div className="email-content__body-area p-2 text">
                <div className={`d-flex flex-column border-0 ${!selectedEmail.subject && 'visually-hidden'}`} role="tablist" id="nav-tab">

                    <Tabs
                        id="nav-tab-mail-content"
                        activeKey={isHTMLactive ? 'html' : 'text'}
                        onSelect={handleMailTabSelect}
                        className="d-flex flex-row border-0 "
                    >
                        <Tab
                            eventKey="html"
                            title="HTML"
                            className={`content_tab-button`}
                        >
                            <div className="tab-content py-3 border-top border-secondary-subtle">
                                {/*<div className="shadow1" id="nav-html"></div>*/}
                                {isHTMLactive && <EmailContentBody type={'html'} emailInfo={selectedEmail} isHighlighted={isHighlighted} />}
                            </div>
                        </Tab>
                        <Tab
                            eventKey="text"
                            title="TEXT"
                            className={`content_tab-button`}
                            disabled={!selectedEmail.text_result_gzip}
                        >
                            <div className="tab-content py-3 border-top border-secondary-subtle">
                                {/*<div className={`shadow2 `} id="nav-text"></div>*/}
                                {isTEXTactive && <EmailContentBody type={'text'} emailInfo={selectedEmail} isHighlighted={isHighlighted} />}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default EmailContent;