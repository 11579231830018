/** @jsxImportSource @emotion/react */
import React from 'react'
import { $CheckResult$Client, $Comment } from '../../graphql/queriesIO'
import CustomDropdown from '../utils/CustomDropdown/CustomDropdown';
import CheckResultComment from './CheckResultsComment'

import { CheckResultItemsStyles } from './CheckResultsGrid.style';

type $ColumnConfig = {
  label: string,
  positionClass?: string,
}
const Columns = {
  Checkable: [
    { label: '番号' },
    { label: '判定' },
    { label: 'タグ' },
    { label: 'リンクテキスト/alt' },
    { label: 'URL/src', positionClass: 'text-start' },
    { label: 'ステータスコード' },
    { label: 'ステータス名称' },
    { label: 'ステータス説明' },
  ] as Array<$ColumnConfig>,
  Uncheckable: [
    // { label: '番号' },
    { label: '判定' },
    { label: 'タグ' },
    { label: 'リンクテキスト/alt' },
    { label: 'URL/src', positionClass: 'text-start' },
    { label: '対象外ルール名' },
    { label: '対象外ルールパターン' },
  ] as Array<$ColumnConfig>,
} as const

const style = CheckResultItemsStyles();

export const CheckResultsGridRowHead = React.memo<{
  variant: 'CheckResult' | 'NoEval',
}>((props) => {
  return props.variant === 'CheckResult' ? (
    <div css={style.header(1)} className="check-result-item__headers pb-4 pt-1 px-1 text-start">
      {Columns.Checkable.map((column, index) => (
        <span key={index} className='check-result-item__header text-secondary-emphasis ps-2'>{column.label}</span>
      ))}
    </div>
  ) : (
    <div css={style.header(2)} className="check-result-item__headers pb-4 pt-1 px-1 text-start">
      {Columns.Uncheckable.map((column, index) => (
        <span key={index} className='check-result-item__header text-secondary-emphasis ps-2'>{column.label}</span>
      ))}
    </div>
  )
})
CheckResultsGridRowHead.displayName = 'CheckResultsGridRowHead'


type $Record = $CheckResult$Client & {
  isHidden?: boolean,
}


export const CheckResultsGridRowBody = React.memo<
  /** Data */
  {
    variant: 'Data$Checkable',
    record: $Record,
    // TODO: [要確認] 以下の分岐は本当に必要か？
    withNotHtmlTag?: boolean,
    withFallbackLinkName?: boolean,
  } | {
    variant: 'Data$Uncheckable',
    record: $Record,
    /** Empty */
  } | {
    variant: 'Empty$Checkable' | 'Empty$Uncheckable',

    /** Skeleton */
  } | {
    variant: 'Skeleton$Checkable' | 'Skeleton$Uncheckable',
    // TODO: [要確認] 以下の分岐は本当に必要か？
    subType: 'useFontSize' | 'usePlaceholderLg',
  }>((props) => {

    /** Data */
    return props.variant === 'Data$Checkable' ? (
      <>
        <div css={style.body(1)} className="check-result-item__results py-3 px-1 border-top text-start">
          <span className='px-2'>{props.record.number_in_same_mail || '-'}</span>
          <span className='px-2'>
            {
              props.record.decision === 'NG' ?
                <CustomDropdown record={props.record} />
                :
                props.record.decision || '-'
            }
          </span>
          {/* @ts-ignore TODO: 本当に html_tag じゃなくて tag で合ってる？ */}
          <span className='px-2'>{(props.withNotHtmlTag ? props.record.tag : props.record.html_tag) || '-'}</span>
          {/* @ts-ignore TODO: 本当に link_name を使う？ */}
          <span className='px-2'>{props.record.link_text || props.record.link_name || '-'}</span>
          <span className='px-2 text-start'>
            {
              props.record.url !== '' ?
                <a href={`${props.record.url}`} target="_blank" className='check-result-item__col text-decoration-none text-success-emphasis'>{props.record.url}</a>
                :
                <span className='check-result-item__col text-success-emphasis'>リンクが設定されていませんでした。</span>
            }
          </span>
          <span className='px-2'>{(props.record.status_code === 0 || props.record.status_code === null) ? '-' : props.record.status_code}</span>
          <span className='px-2'>{(props.record.status_code === 0 ? '-' : props.record.status_title) || '-'}</span>
          <span className='px-2'>
            {props.record.status_code_description_ja || '-'}
            {props.record.isHidden && (
              <span css={style.textBold()}>対象のタグが非表示です。</span>
            )}
          </span>
        </div>
        {/* CheckResultにコメントがある場合表示する */}
        {props.record.comments?.items && props.record.comments.items.length > 0 &&
          props.record.comments.items.sort((a,b) => a.changed < b.changed ? 1 : -1).map((comment: $Comment, idx) => {
            return (
              <CheckResultComment key={idx} comment={comment} />
            )
          })}
      </>

    ) : props.variant === 'Data$Uncheckable' ? (
      <>
        <div css={style.body(2)} className="check-result-item__results py-3 px-1 border-top text-start">
          {/*<span className='px-2'>{props.record.number_in_same_mail || '-'}</span>*/}
          <span className='px-2'>{props.record.decision || '-'}</span>
          {/* @ts-ignore TODO: 本当に html_tag じゃなくて tag で合ってる？ */}
          <span className='px-2'>{props.record.html_tag || '-'}</span>
          <span className='px-2'>{props.record.link_text || '-'}</span>
          <span className='px-2 text-start'>
            {
              props.record.url !== '' ?
                <a href={`${props.record.url}`} target="_blank" className='check-result-item__col text-decoration-none text-success-emphasis'>{props.record.url}</a>
                :
                <span className='check-result-item__col text-success-emphasis'>リンクが設定されていませんでした。</span>
            }
          </span>
          <span className='px-2'>{props.record.exclude_rule_name || '-'}</span>
          <span className='px-2'>{props.record.exclude_rule_pattern || '-'}</span>
        </div>
        {props.record.comments?.items && props.record.comments.items.length > 0 &&
          props.record.comments.items.sort((a,b) => a.changed < b.changed ? 1 : -1).map((comment: $Comment, idx) => {
            return (
              <CheckResultComment key={idx} comment={comment} />
            )
          })}
      </>

      /** Empty */
    ) : props.variant === 'Empty$Checkable' ? (
      <div css={style.body(1)} className="check-result-item__results1 check-result-item__results py-3 px-1 border-top text-start" style={{ fontSize: 7 + 'px' }}>
        {Columns.Checkable.map((column, index) => (
          <span key={index} className={`px-2 ${column.positionClass ?? ''}`}>-</span>
        ))}
      </div>
    ) : props.variant === 'Empty$Uncheckable' ? (
      <div css={style.body(2)} className="check-result-item__results2 check-result-item__results py-3 px-1 border-top text-start" style={{ fontSize: 7 + 'px' }}>
        {Columns.Uncheckable.map((column, index) => (
          <span key={index} className={`px-2 ${column.positionClass ?? ''}`}>-</span>
        ))}
      </div>


      /** Skeleton */
    ) : props.variant === 'Skeleton$Checkable' ? (
      <div css={style.body(1)} className={`check-result-item__results1 check-result-item__results py-3 px-1 border-top text-start placeholder-glow ${props.subType === 'usePlaceholderLg' ? 'placeholder-lg' : ''}`} style={props.subType === 'useFontSize' ? { fontSize: 7 + 'px' } : undefined}>
        {Columns.Checkable.map((_, index) => (
          <span key={index} className='px-2 placeholder me-1'></span>
        ))}
      </div>
    ) : props.variant === 'Skeleton$Uncheckable' ? (
      <div css={style.body(2)} className={`check-result-item__results1 check-result-item__results py-3 px-1 border-top text-start placeholder-glow ${props.subType === 'usePlaceholderLg' ? 'placeholder-lg' : ''}`} style={props.subType === 'useFontSize' ? { fontSize: 7 + 'px' } : undefined}>
        {Columns.Uncheckable.map((_, index) => (
          <span key={index} className='px-2 placeholder me-1'></span>
        ))}
      </div>
    ) : null
  })
CheckResultsGridRowBody.displayName = 'CheckResultsGridRowBody'

