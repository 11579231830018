import Dexie from 'dexie';

export const idb = new Dexie('emailsAndCheckResults');
idb.version(2).stores({
    emails: '++id, archived, read, received',
    checkResults: '++id, mail_id'
    // checkResults: '++id, mail_id, updatedAt'  <= version 3
});

// emailsInfo

export const addAllEmailsToIdb = async (emailData) => {
    try {
        const result = await idb
            .table('emails')
            .bulkAdd(emailData);
        return result;
    } catch (error) {
        if (error instanceof Dexie.BulkError) {
            console.error('Bulk operation failed for some items:', error.failures);
        } else {
            console.error(error);
        }
    }
}

export const putAllEmailsToIdb = async (emailData) => {
    try {
        const result = await idb
            .table('emails')
            .bulkPut(emailData);
        return result;
    } catch (error) {
        if (error instanceof Dexie.BulkError) {
            console.error('Bulk operation failed for some items:', error.failures);
        } else {
            console.log('error in putAllEmailsToIdb')
            console.error(error);
        }
    }
}

export const addEmailToIdb = async (email) => {
    try {
        await idb
            .table('emails')
            .add(email)
            .then(() => console.log('Email Added: ', email.id))
            .catch(err => console.error(err))
    } catch (error) {
        console.error(error);
    }
}

export const putEmailToIdb = async (email) => {
    try {
        const result = await idb
            .table('emails')
            .put(email)
        console.log('put this archived mail into IDB: ', result)
        return result;
    } catch (error) {
        console.error(error);
    }
}

export const getAllEmailsFromIdb = async () => {
    try {
        const allEmailsData = await idb
            .table('emails')
            .reverse()
            .sortBy('received')
        console.log('allEmailsData from idb: ', allEmailsData)
        return allEmailsData;
    } catch (error) {
        console.log('error in getAllEmailsFromIdb')
        console.error(error);
    }
}

export const getNonArchivedEmailsFromIdb = async () => {
    try {
        const result = await idb
            .table('emails')
            .where('archived')
            .equals(0)
            .reverse()
            .sortBy('received')
        console.log('non-archived emails after update: ', result)
        return result;
    } catch (error) {
        console.log('error in getNonArchivedEmailsFromIdb')
        console.error(error);
    }
}

// checkResults

// 指定mail_idのチェック結果を取る
// ↓
/** @type {(mail_id: string) => Promise<Array<import('../graphql/queriesIO').$CheckResult>> }*/
export const getCheckResult = async (mail_id) => {
    try {
        const checkResultsData = await idb
            .table('checkResults')
            .where('mail_id')
            .equals(mail_id)
            .toArray()
        console.log(`check results for ${mail_id}: `, checkResultsData)
        return checkResultsData;
    } catch (error) {
        console.log('error in getCheckResult')
        console.error(error);
    }
}

export const putCheckResultstoIdb = async (checkResults) => {
    try {
        const checkResultsData = await idb
            .table('checkResults')
            .bulkPut(checkResults)
        console.log('checkResultsData: ', checkResultsData);
        return checkResultsData;
    } catch (error) {
        if (error instanceof Dexie.BulkError) {
            console.error('Bulk operation failed for some items:', error.failures);
        } else {
            console.error(error);
        }
    }
}

export const putCheckResultToIdb = async (record) => {
    try {
        const result = await idb
            .table('checkResults')
            .put(record)
        console.log('put this updated check result into IDB: ', result)
        return result;
    } catch (error) {
        console.error(error);
    }
}

export const closeConnection = () => {
    idb.close()

    idb.delete().then(() => {
        console.log("データベースを削除しました。");
    }).catch((err) => {
        console.error("データベースを削除できませんでした。");
    });
}

// IndexedDBのテーブルをクリアにする
// ↓
export const clearDb = async () => {
    try {
        // テーブルが既に存在している場合のみにクリアにする
        if (idb.table('emails').schema.primKey && idb.table('checkResults').schema.primKey) {
            await idb.table('emails').clear()
            await idb.table('checkResults').clear()
            console.log('Cleared DB')
        } else {
            console.log('Couldnt clear DB')
        }
    } catch (error) {
        console.error(error)
    }
}