/** @jsxImportSource @emotion/react */
import React from 'react'
import { $Comment } from '../../graphql/queriesIO'

import { CheckResultCommentStyles } from './CheckResultComment.style'

type CheckResultCommentProps = {
    comment: $Comment
}

const CheckResultComment = ({ comment }: CheckResultCommentProps) => {

    const style = CheckResultCommentStyles()

    return (
        <div css={style.main}>
            <div className='comment__container text-secondary'>
                <div className='comment__grid-item'>
                    <span>{ comment.changed && comment.changed.substring(0, 10) + " " + comment.changed.substring(11, 19) }</span>
                </div>
                <div className='comment__grid-item'>
                    <span css={style.statusColor(comment.previous_status)} className='comment__prev-status'>{ comment.previous_status }</span>
                    <span> → </span>
                    <span css={style.statusColor(comment.new_status)} className='comment__new-status'>{ comment.new_status }</span>
                </div>
                <span className='comment__grid-item'>{comment.editor}</span>
                <span className='comment__grid-item_comment'>{comment.comment_body}</span>
            </div>
        </div>
    )
}

export default React.memo(CheckResultComment)