/** @jsxImportSource @emotion/react */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import { VscTriangleDown, VscTriangleRight } from 'react-icons/vsc'


export const InputToggle = React.memo<{
  opened:  boolean,
  onClick: () => void,
}>((props) => {
  const Icon = props.opened ? VscTriangleDown : VscTriangleRight
  return(
    <Icon onClick={props.onClick} />
  )
})
InputToggle.displayName = 'InputToggle'

// TODO: アコーディオン部分に関しては、一旦直接 DOM 操作する形になっている
export function useInputToggle(params?: {
  opened?: boolean,
}) {
  const [opened, setOpened] = useState<boolean>(params?.opened ?? false)
  const [enabledAnimation, setEnabledAnimation] = useState<boolean>(false)
  useEffect(() => {
    if (params?.opened && params.opened === opened) setOpened(params.opened)
  }, [params?.opened])

  const onClick = useCallback(() => {
    setEnabledAnimation(true)
    setOpened(!opened)
  }, [opened])

  const toggleBodyRef = useRef<HTMLElement>(null)
  const toggleSpeed = 8

  useEffect(() => {
    if (!enabledAnimation) {
      // 初期表示用
      if (!toggleBodyRef.current) return
      if (opened) {
        toggleBodyRef.current.style.maxHeight = 'unset'
      } else {
        toggleBodyRef.current.style.maxHeight = '0'
      }
    } else {
      if (opened) {
        const interval = setInterval(() => {
          if (!toggleBodyRef.current) {
            clearInterval(interval)
            return
          }
          const bfHeight = toggleBodyRef.current.clientHeight
          toggleBodyRef.current.style.maxHeight = `${toggleBodyRef.current.clientHeight + toggleSpeed}px`
          if (bfHeight === toggleBodyRef.current.clientHeight) {
            clearInterval(interval)
            toggleBodyRef.current.style.maxHeight = 'unset'
            return
          }
        }, 1)
      } else {
        const interval = setInterval(() => {
          if (!toggleBodyRef.current) {
            clearInterval(interval)
            return
          }
          if (toggleBodyRef.current.clientHeight - toggleSpeed > 0) {
            toggleBodyRef.current.style.maxHeight = `${toggleBodyRef.current.clientHeight - toggleSpeed}px`
          } else {
            toggleBodyRef.current.style.maxHeight = '0'
            clearInterval(interval)
            return
          }
        }, 1)
      }
    }
  }, [opened, enabledAnimation, toggleBodyRef])

  return {
    opened, setOpened, onClick,
    toggleBodyRef,
  }
}
