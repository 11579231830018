import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function CustomToast({
    showToast,
    toastMessage,
    toggleShowToast }) {

    const isError = false;
    const currentTime = new Date();
    return (
        <ToastContainer
            position="bottom-end"
            className="p-3">
            <Toast
                show={showToast}
                onClose={toggleShowToast}
                delay={5000}
                autohide
            >
                <Toast.Header>
                    <strong className="me-auto">GMA Link Checker</strong>
                    <small>{`${currentTime.toLocaleDateString()} ${currentTime.toLocaleTimeString()}`}</small>
                </Toast.Header>
                <Toast.Body>{toastMessage || '変更しました。'}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default CustomToast;