//@ts-check

import React, { useEffect, useState } from "react";
import './Plan.css';

function Plan() {

    const data = [
        {
            name: '無料プラン',
            price: '0',
            maxMails: 30,
            checksExp: 1,
            maxAdditionalMails: 1,
            accounts: 1,
        },
        {
            name: 'ベーシック',
            price: '10,000',
            maxMails: 300,
            checksExp: 6,
            maxAdditionalMails: 5,
            accounts: 5,
        },
        {
            name: 'スタンダード',
            price: '40,000',
            maxMails: 1500,
            checksExp: '12',
            maxAdditionalMails: 10,
            accounts: 10,
        },
        {
            name: 'アドバンス',
            price: '70,000',
            maxMails: 3000,
            checksExp: '制限なし',
            maxAdditionalMails: 20,
            accounts: '制限なし',
        },
    ]

    return (

        // <div className="plan_container d-flex flex-column bg-body shadow my-3 px-3 w-75">
        //     <div className='set-item_title-area d-flex flex-row pb-0 pt-3'>
        //         <h3 className='set-item_title me-auto px-2 pb-4 fw-semibold'>プラン概要</h3>
        //     </div>
        //     <div className="plan_content-block border-1">

        //     </div>
        // </div>
        <>
            <div className="plan_container scroll" id="scroll-container">
                {/* <div>
                <h3>

                </h3>
                <div className="plan_deals-container p-4">
                    <div className="plan__card card w-25 rounded-0">
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2 text-muted">オプション</h6>
                            <h5 className="card-title fw-bold">ブースト</h5>
                            <p className="card-text cards-custom-font-12">契約したお客様が通数を増やす場合に利用するメニューになります。</p>
                            <a href="#about-booster" class="btn btn-success">詳しく</a>
                        </div>
                    </div>
                </div>
                <div className="plan_deals-container">
                    <div className="card">

                    </div>
                </div>
                <div className="plan_deals-container">
                    <div className="card">

                    </div>
                </div>
            </div> */}

                <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                    <h3 className="fw-bold">
                        プラン
                    </h3>

                    <p className="fw-semibold text-secondary mb-5">
                        プラン変更はWebからいつでもお申し込み可能です。
                    </p>

                    <div className="plan_grid-container">
                        <div className="plan_grid-item d-flex flex-column align-items-center justify-content-center">
                            <div className="plan__card cardrounded-0">
                                <div className="card-body">
                                    <h6 className="card-subtitle mb-2 text-muted">オプション</h6>
                                    <h5 className="card-title fw-bold">ブースト</h5>
                                    <p className="card-text cards-custom-font-12">契約したお客様が通数を増やす場合に利用するメニューになります。</p>
                                    <a href="#about-booster" className="btn btn-success">詳しく</a>
                                </div>
                            </div>
                        </div>
                        {
                            data.map((c, index) => {
                                return (
                                    <div className="plan_grid-item border-bottom-0 border d-flex flex-column align-items-center justify-content-center" key={index} >
                                        <div className="card plan-card rounded-0 shadow-none border-0 text-body">
                                            <h5 className="card-title border-0 fw-bold pt-4 ps-3">{c.name}</h5>
                                            <div className="card-body d-flex flex-column justify-content-between">
                                                <p className="fs-5 mb-0"><strong>{`￥${c.price}`}</strong></p>
                                                <p className="fs-5 mb-2 text-end">／月 <span className="fw-light cards-custom-font-11">（税抜き）</span></p>
                                                <p className="card-text lh-1 mb-1 card-custom-font-10 text-muted text-secondary ">※ 初期費用無料</p>
                                                <p className="card-text lh-1 mb-1 card-custom-font-10 text-muted text-secondary">※ 6か月間契約（契約月一括請求）</p>

                                                {/* <ul className="plan-card_details mt-4 mb-5 ps-2 lh-sm card-custom-font-10">
                                                <li>{`通数：上限${c.maxMails}通/月`}</li>
                                                <li>{`アカウント数：${c.accounts}個`}</li>
                                                <li>{`チェック結果有効期限：${c.checksExp}ヵ月`}</li>
                                                <li>{`通知先：${c.maxAdditionalMails}個`}</li>
                                            </ul> */}
                                                <p className="card-custom-font-10 lh-sm mb-2 text-muted text-secondary">※ 6ヶ月間契約のため、通数の月での上限はなく、6ヶ月間の上限になります。</p>
                                                <button className="btn btn-success w-100 mt-1" type="submit">変更</button>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }


                        <div className="plan_grid-item plan_grid-item-container border text-secondary card-custom-font-11">
                            <p className="plan_grid-item-sm plan_grid-item-sm-1 justify-content-start bg-body-secondary fw-bold text-start">プランについて</p>
                            <p className="plan_grid-item-sm border-bottom justify-content-start text-start">契約期間</p>
                            <p className="plan_grid-item-sm border-bottom justify-content-start">通数</p>
                            <p className="plan_grid-item-sm border-bottom justify-content-start text-start">アカウント数</p>
                            <p className="plan_grid-item-sm border-bottom justify-content-start text-start">チェック結果有効期限</p>
                            <p className="plan_grid-item-sm border-bottom justify-content-start text-start">通知先</p>
                        </div>
                        <div className="plan_grid-item plan_grid-item-container border text-secondary card-custom-font-11">
                            <p className="plan_grid-item-sm plan_grid-item-sm-1 bg-body-secondary"></p>
                            <p className="plan_grid-item-sm border-bottom">1ヶ月間</p>
                            <p className="plan_grid-item-sm border-bottom">30通まで</p>
                            <p className="plan_grid-item-sm border-bottom">1個</p>
                            <p className="plan_grid-item-sm border-bottom">1ヶ月</p>
                            <p className="plan_grid-item-sm border-bottom">1個</p>
                        </div>
                        <div className="plan_grid-item plan_grid-item-container border text-secondary card-custom-font-11">
                            <p className="plan_grid-item-sm plan_grid-item-sm-1 bg-body-secondary"></p>
                            <p className="plan_grid-item-sm border-bottom">6ヶ月間</p>
                            <p className="plan_grid-item-sm border-bottom">300通まで</p>
                            <p className="plan_grid-item-sm border-bottom">5個</p>
                            <p className="plan_grid-item-sm border-bottom">6ヶ月</p>
                            <p className="plan_grid-item-sm border-bottom">5個</p>
                        </div>
                        <div className="plan_grid-item plan_grid-item-container border text-secondary card-custom-font-11">
                            <p className="plan_grid-item-sm plan_grid-item-sm-1 bg-body-secondary"></p>
                            <p className="plan_grid-item-sm border-bottom">6ヶ月間</p>
                            <p className="plan_grid-item-sm border-bottom">1500通まで</p>
                            <p className="plan_grid-item-sm border-bottom">10個</p>
                            <p className="plan_grid-item-sm border-bottom">12ヶ月</p>
                            <p className="plan_grid-item-sm border-bottom">10個</p>
                        </div>
                        <div className="plan_grid-item plan_grid-item-container border text-secondary card-custom-font-11">
                            <p className="plan_grid-item-sm plan_grid-item-sm-1 bg-body-secondary"></p>
                            <p className="plan_grid-item-sm border-bottom">6ヶ月間</p>
                            <p className="plan_grid-item-sm border-bottom">3000通まで</p>
                            <p className="plan_grid-item-sm border-bottom">制限なし</p>
                            <p className="plan_grid-item-sm border-bottom">制限なし</p>
                            <p className="plan_grid-item-sm border-bottom">20個</p>
                        </div>
                    </div>

                    <div className="card__boost-container d-flex flex-column align-items-center w-100 mt-5  mb-5"
                        id="about-booster"
                    >
                        <h3 className="fw-bold mb-5">
                            ブースト
                        </h3>
                        <div className="rounded border w-100 d-flex flex-column align-items-center py-3">
                            <p className="fw-semibold text-secondary">
                                追加料金：￥10,000／100通追加ごと
                            </p>
                            <p className="lh-1 mb-1 card-custom-font-10 text-muted text-secondary">
                                ※ 無料プランご利用のお客様はご利用いただけません。
                            </p>
                            <div className="card__boost-button d-grid gap-2 w-50 mb-3 mt-2">
                                <button className="btn btn-success fw-semibold" type="button">ブーストを申し込む</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
} export default Plan;