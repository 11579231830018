// @ts-check
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Amplify, API, Auth, graphqlOperation } from 'aws-amplify';
import awsmobile from "./aws-exports";
import { Authenticator, useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import {
	listAdditionalMailAddresses,
	listEmailInfos,
	listSpecificPatterns,
	listTenantConfigs,
	emailInfosByTenantIndexAndReceived,
} from './graphql/queries';
import {
	createAdditionalMailAddress,
	createSpecificPattern,
	deleteAdditionalMailAddress,
	deleteEmailInfo,
	deleteSpecificPattern,
	updateAdditionalMailAddress,
	updateEmailInfo,
	updateSpecificPattern,
	updateTenantConfig
} from './graphql/mutations';

import Main from './components/Main/Main';
import Settings from './components/Settings/Settings';
import components from './utils/signinconfigs';
import { onUpdateCheckResult, onUpdateEmailInfo } from './graphql/subscriptions';
import Contract from './components/ContractPage/Contract';
import { subscribe } from 'graphql';
import { onCreateEmailInfo } from './graphql/subscriptions';
import * as idb from './utils/indexeddb';
import { CurrentUserContext } from './contexts/CurrentUserContext';
import { useEmailInfoListDataContext } from './contexts/data/EmailInfoListDataContext'
import { currentEmailInfoItemContext } from './contexts/data/EmailInfoItemDataContext'
import { RecordUpdateContext } from './contexts/data/CheckResultUpdateDataContext';

Amplify.configure(awsmobile);

function App() {
	const emailInfoListDataContext = useEmailInfoListDataContext()
	const currentEmailInfo = currentEmailInfoItemContext.useConsumer()
	const { updateRecordLocally } = RecordUpdateContext.useData()

	const [currentUser, setCurrentUser] = useState({}); // ログインしたユーザーの情報
	// @ts-ignore
	useEffect(() => emailInfoListDataContext.setCurrentUser(!!currentUser.email ? currentUser : undefined), [currentUser])
	// const [emailList, setEmailList] = useState([]); // 全てのメール
	// const emailList = emailInfoListDataContext.originalList
	const setEmailList = useCallback(emailInfoListDataContext.setOriginalList, [])
	// const [selectedEmail, setSelectedEmail] = useState({}); // 中身が表示中のメール
	const selectedEmail = currentEmailInfo.item
	const setSelectedEmail = useCallback(currentEmailInfo.setItem, [])
	const [tenantConfigs, setTenantConfigs] = useState({}); // テナントのコンフィグ
	const [additionalMailAddresses, setAdditionalMailAddresses] = useState([]); // 通知先
	const [temporaryList, setTemporaryList] = useState([]); // ミューテーション用通知先メールアドレスのリスト
	const [temporaryPatternList, setTemporaryPatternList] = useState([]);
	const [temproraryConfigs, setTemproraryConfigs] = useState({})

	const [patterns, setPatterns] = useState([]);
	const [isSubsClosed, setIsSubsClosed] = useState(false)
	const [isNextToken, setIsNextToken] = useState(false)

	let nextToken = null;

	const signOut = async () => {
		try {
			// IDBをクローズして削除する
			idb.closeConnection();
			// ステータスをクリアにする
			setCurrentUser('');
			setEmailList([]);
			setTenantConfigs({});
			setAdditionalMailAddresses([]);
			// localStorageをクリアにする
			localStorage.clear();
			// AmplifyAuthからサインアウトする
			await Auth.signOut();
		} catch (error) {
			console.log('error signing out: ', error);
		}
	}

	const fetchUser = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			if (user) {
				const userData = {
					email: user.attributes.email,
					tenant: user.signInUserSession.accessToken.payload['cognito:groups'][0],
					username: user.username,
				}
				setCurrentUser(userData);
				localStorage.setItem('user', JSON.stringify(userData));
				console.log('local storage user: ', userData);
				return user.signInUserSession.accessToken.payload['cognito:groups'][0];
			}

		} catch (error) {
			console.log(error);
		}
	}

	const fetchEmails = async (tenant, isOnRefresh) => {
		try {
			setIsNextToken(true)
			console.log('token before fetch: ', nextToken)
			// const emailsData = await API.graphql(graphqlOperation(listEmailInfos, {limit: 20}));
			// @ts-ignore
			// const emailsData = await API.graphql(graphqlOperation(emailInfosByTenantIndexAndReceived, { tenantIndex: tenant, limit: 1000, sortDirection: 'DESC' }));
			const emailsData = await API.graphql(graphqlOperation(emailInfosByTenantIndexAndReceived, {
				tenantIndex: tenant,
				// filter: { archived: { eq: 0 } },
				limit: 100,
				sortDirection: 'DESC',
				nextToken: nextToken
			}));
			// const emails = emailsData.data.emailInfosByTenantIndex.items.sort((a, b) => b.received.localeCompare(a.received));
			// const emails = emailsData.data.emailInfosByTenantIndexAndReceived.items;
			const results = emailsData.data.emailInfosByTenantIndexAndReceived;
			//setEmailList(emails);
			// console.log('data: ', emailsData)
			// console.log('next token: ', results.nextToken)
			// console.log('email items: ', results.items)
			nextToken = results.nextToken;

			assignEmails(results, tenant, isOnRefresh);
		} catch (error) {
			console.log(error);
		}
	}

	const updateEmailsOnArchived = async (updatedData) => {
		try {
			// subscriptionの状況を確認する
			// クローズの場合こちらからアップデート関数を実行する
			// ↓
			if (isSubsClosed) {
				console.log('subs is closed. running function from here')
				updateEmailsOnUpdateSubscription(updatedData);
			} else {
				console.log('subs is OK. waiting for function to run')
			}
		} catch (error) {
			console.log(error);
		}

	}

	const updateEmailsOnRead = async (obj, read) => {
		console.log('read status of the email: ', read)
		try {
			const updateInput = await API.graphql(graphqlOperation(updateEmailInfo, {
				input: {
					id: obj.id,
					read: read,
				}
			}));
			const updatedData = updateInput.data.updateEmailInfo;
			console.log('updated email: ', updatedData)

			// subscriptionの状況を確認する
			// クローズの場合こちらからアップデート関数を実行する
			// ↓
			if (isSubsClosed) {
				console.log('subs is closed. running function from here')
				updateEmailsOnUpdateSubscription(updatedData);
			} else {
				console.log('subs is OK. waiting for function to run')
			}

		} catch (error) {
			console.log(error)
		}
	}

	const fetchTenantConfigs = async (tenant) => {
		try {
			const tenatnConfigData = await API.graphql(graphqlOperation(listTenantConfigs, { filter: { tenant: { eq: tenant } } }));
			const configsData = tenatnConfigData.data.listTenantConfigs.items[0];
			setTenantConfigs(configsData);
			setTemproraryConfigs(configsData);
			localStorage.setItem('configs', JSON.stringify(configsData));
			console.log('local storage configs: ', localStorage.getItem('configs'));
		} catch (error) {
			console.log(error);
		}
	}

	const fetchAdditionalMailAddresses = async (tenant) => {
		try {
			const additionalMailAddressesData = await API.graphql(graphqlOperation(listAdditionalMailAddresses, { filter: { tenant: { eq: tenant } } }));
			const additionalMails = additionalMailAddressesData.data.listAdditionalMailAddresses.items.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
			setAdditionalMailAddresses(additionalMails);
			setTemporaryList(additionalMails);
			localStorage.setItem('additionalMails', JSON.stringify(additionalMails));
			console.log('local storage additionalMails: ', additionalMails);
		} catch (error) {
			console.log(error);
		}
	}

	const createMailAddress = async (obj) => {
		console.log('creating next object: ', obj)
		try {
			const createInput = await API.graphql(graphqlOperation(createAdditionalMailAddress, {
				input: {
					email_address: obj.email_address,
				}
			}))
			const createdData = createInput.data.createAdditionalMailAddress;
			// save to a state
		} catch (error) {
			console.log(error);
		}
	}

	const updateMailAddresses = async (obj) => {
		try {
			const updateInput = await API.graphql(graphqlOperation(updateAdditionalMailAddress, {
				input: {
					email_address: obj.email_address,
					id: obj.id
				}
			}));
			const updatedData = updateInput.data.updateAdditionalMailAddress;
			fetchAdditionalMailAddresses(currentUser.tenant)
		} catch (error) {
			console.log(error)
		}
	}

	const deletelMailAddress = async (id) => {
		try {
			const deletedData = await API.graphql(graphqlOperation(deleteAdditionalMailAddress, { input: { id } }))
		} catch (error) {
			console.log(error)
		}
	}

	const fetchSpecificPatterns = async (tenant) => {
		try {
			const patternsData = await API.graphql(graphqlOperation(listSpecificPatterns, { filter: { tenant: { eq: tenant } } }));
			const patterns = patternsData.data.listSpecificPatterns.items;
			setPatterns(patterns);
			setTemporaryPatternList(patterns);
			localStorage.setItem('specificPatterns', JSON.stringify(patterns))
			console.log('specificPatterns for current tenant: ', patterns);
		} catch (error) {
			console.log(error);
		}
	}

	const createPatterns = async (obj) => {
		try {
			const createdData = await API.graphql(graphqlOperation(createSpecificPattern, {
				input: {
					enabled: obj.enabled,
					name: obj.name,
					pattern: obj.pattern,
					type: obj.type,
					tenant: currentUser.tenant
				}
			}))
			fetchSpecificPatterns(currentUser.tenant);
		} catch (error) {
			console.log(error);
		}
	}

	const updatePatterns = async (obj) => {
		console.log('object befor updating', obj)
		try {
			const updatedData = await API.graphql(graphqlOperation(updateSpecificPattern, {
				input: {
					id: obj.id,
					enabled: obj.enabled,
					name: obj.name,
					pattern: obj.pattern,
					type: obj.type
				}
			}))
			fetchSpecificPatterns(currentUser.tenant);
		} catch (error) {
			console.log(error);
		}
	}

	const deletePatterns = async (obj) => {
		try {
			const deletedData = await API.graphql(graphqlOperation(deleteSpecificPattern, {
				input: {
					id: obj.id,
				}
			}))
		} catch (error) {
			console.log(error);
		}
	}

	const updateTenant = async (obj) => {
		try {
			// const configs = tenantConfigs;
			const tenantData = await API.graphql(graphqlOperation(updateTenantConfig, {
				input: {
					id: obj.id,
					email_for_check: obj.email_for_check,
				}
			}));
			console.log(tenantData.data.updateTenantConfig);
			setTenantConfigs(tenantData.data.updateTenantConfig);

		} catch (error) {
			console.log(error);
		}
	}


	// ========= SUBSCRIPTIONS ==========


	// const updateEmailsOnArchivedSubscription = async (email) => {

	// 	console.log('updating after subscription fired')

	// 	try {
	// 		// IDB上で非表示にされたメールを1通づつ更新していく
	// 		// ↓
	// 		await idb.putEmailToIdb(email)

	// 		// archived:0のメールをIDBから取る
	// 		// ↓
	// 		const currentEmails = await idb.getNonArchivedEmailsFromIdb() ?? []
	// 		console.log('currentEmails: ', currentEmails)

	// 		setEmailList(currentEmails);
	// 	} catch (error) {
	// 		console.error(error)
	// 	}
	// 	// const currentEmails = JSON.parse(localStorage.getItem('emails') || '[]');
	// 	// const list = currentEmails.length > 0 ? currentEmails.filter(e => e.id !== email.id) : [];
	// 	// localStorage.setItem('emails', JSON.stringify(list));
	// }

	// const updateEmailsOnReadSubscription = async (email) => {

	// 	console.log('updating after subscription fired')

	// 	try {
	// 		// DynamoDB上でread:1へ変更する
	// 		// ↓
	// 		await idb.putEmailToIdb(email);

	// 		// readが更新されたメールをIDBから取る
	// 		// ↓
	// 		const currentEmails = await idb.getNonArchivedEmailsFromIdb() ?? []

	// 		setEmailList(currentEmails);

	// 	} catch (error) {
	// 		console.error(error)
	// 	}
	// }

	const updateEmailsOnUpdateSubscription = async (email) => {
		console.log('updating after subscription fired')

		try {
			// DynamoDB上でreadかarchivedを変更する
			// ↓
			await idb.putEmailToIdb(email);

			// readかaarchivedが更新され未非表示メールをIDBから取る
			// ↓
			const currentEmails = await idb.getAllEmailsFromIdb() ?? []
			console.log('currentEmails: ', currentEmails)

			setEmailList(currentEmails);

		} catch (error) {
			console.error(error)
		}

	}

	// TODO
	const updateEmailsOnCreateSubscription = async (email) => {

		const currentEmails = JSON.parse(localStorage.getItem('emails') || '[]');

		currentEmails.unshift(email);
		console.log('currentEmails after unshift: ', currentEmails);

		setEmailList(currentEmails);
		//localStorage.setItem('emails', JSON.stringify(currentEmails));
	}


	// ========= /SUBSCRIPTIONS ==========


	// const updateLocalStorageEmailsOnRead = (data) => {



	// 	//const localEmails = JSON.parse(localStorage.getItem('emails') || '[]');
	// 	console.log('local list: ', localEmails)

	// 	// get index of an old version of updated email in local storage
	// 	const index = localEmails.findIndex(e => e.id === data.id)
	// 	console.log('index: ', index)

	// 	// replace with a newer version
	// 	localEmails.splice(index, 1, data)
	// 	setEmailList(localEmails);
	// 	console.log('updatedList: ', localEmails)

	// 	localStorage.setItem('emails', JSON.stringify(localEmails));

	// }

	const assignEmails = async (data, tenant, isOnRefresh) => {
		// newly fetched emails
		const addedEmails = data.items;

		// if local storage is empty put new items inside
		// if not - proceed to check
		// let localEmails = JSON.parse(localStorage.getItem('emails') || '[]')
		let localEmails = await idb.getAllEmailsFromIdb() ?? []

		if (localEmails.length === 0) {
			// localStorage.setItem('emails', JSON.stringify(addedEmails))
			// setIsNextToken(true)
			idb.putAllEmailsToIdb(addedEmails);
			setEmailList(addedEmails);
			fetchEmails(tenant, false);
		} else {
			// get all locally existing ids
			const ids = localEmails.map(e => {
				return e.id
			})
			// console.log('ids of all locam emails', ids)

			// check if if there is at least one new email with the same id (double)
			let doublicates = [];

			for (let i = 0; i < addedEmails.length; i++) {
				console.log('added id to compare: ', addedEmails[i].id)
				if (!ids.includes(addedEmails[i].id)) {
					if (isOnRefresh) {
						localEmails.unshift(addedEmails[i]);
					} else {
						localEmails.push(addedEmails[i]);  // if a new email's id is not present - add it to the local list
					}
					// localStorage.setItem('emails', JSON.stringify(localEmails));
					idb.putAllEmailsToIdb(localEmails);
					setEmailList(localEmails);
					// console.log('local emails: ', localEmails)
					// console.log('added following email to local list: ', addedEmails[i])
				} else {
					doublicates.push(addedEmails[i])   // if it IS present - stop fetching
					idb.putAllEmailsToIdb(localEmails);
					setEmailList(localEmails)
					break;
				}
			}

			// if douplicates list is empty it meant no douplicated emails, so fetch again
			// and add new emails to the local storage
			if (doublicates.length === 0) {
				setIsNextToken(true)
				console.log('NEXT TOKEN IS NOT NULL. FETCHING')
				fetchEmails(tenant, false);
			} else {
				console.log('ENDING')
				setIsNextToken(false)
				return;
			}
		}
	}

	const handleItemClick = (email) => {
		setSelectedEmail(email);
	}

	const resetSelectedEmail = () => {
		setSelectedEmail({});
	}

	//マウント時にemailInfoの更新にサブスクリプションを設定
	useEffect(() => {
		const subscriptionOnUpdateEmailInfo = API.graphql(graphqlOperation(onUpdateEmailInfo))
			.subscribe({
				next: ({ provider, value }) => {
					const data = value.data.onUpdateEmailInfo;
					console.log('subscription data: ', data);
					console.log(provider)

					updateEmailsOnUpdateSubscription(data)
				},
				error: (error) => console.warn(error)
			})

		const subscriptionOnCreateEmailInfo = API.graphql(graphqlOperation(onCreateEmailInfo))
			.subscribe({
				next: ({ provider, value }) => {
					const data = value.data.onCreateEmailInfo;
					console.log('subscription value: ', data);
					updateEmailsOnCreateSubscription(data)
				},
				error: (error) => console.warn(error)
			})

		const subscriptionOnUpdateCheckResult = API.graphql(graphqlOperation(onUpdateCheckResult))
			.subscribe({
				next: ({ provider, value }) => {
					const data = value.data.onUpdateCheckResult
					console.log('subscribe value on update CheckResult: ', data)

					updateRecordLocally(data)
				},
				error: (error) => console.warn(error)
			})

		return () => {
			subscriptionOnUpdateEmailInfo.unsubscribe()
			subscriptionOnCreateEmailInfo.unsubscribe()
			subscriptionOnUpdateCheckResult.unsubscribe()
		}
	}, [])

	useEffect(() => {
		// localStorage.setItem('emails', [])
		// idb.getAllEmailsFromIdb();
		
		idb.idb.open();
		fetchUser()
			.then((tenant) => {
				console.log(tenant)
				fetchEmails(tenant);
				fetchTenantConfigs(tenant);
				fetchAdditionalMailAddresses(tenant);
				fetchSpecificPatterns(tenant);
			})
	}, [])

	return (
		<div className="app" id="app-pills-tab" role="tablist">
			<CurrentUserContext.Provider value={{ currentUser, tenantConfigs }}>
				<Routes>
					<Route
						path='/sharedbox'
						element={
							<Main
								type={'sharedBox'}
								resetSelectedEmail={resetSelectedEmail}
								handleItemClick={handleItemClick}
								isNextToken={isNextToken}
								// deleteEmail={deleteEmail}
								signOut={signOut}
								fetchEmails={fetchEmails}
								updateEmailsOnRead={updateEmailsOnRead}
								updateEmailsOnArchived={updateEmailsOnArchived}
							//handleOtherButtonClick={handleOtherButtonClick}
							/>
						} />
					<Route
						path='/'
						element={
							<Main
								type={'personalBox'}
								resetSelectedEmail={resetSelectedEmail}
								handleItemClick={handleItemClick}
								isNextToken={isNextToken}
								// deleteEmail={deleteEmail}
								signOut={signOut}
								fetchEmails={fetchEmails}
								updateEmailsOnRead={updateEmailsOnRead}
								updateEmailsOnArchived={updateEmailsOnArchived}
							/>
						} />
					<Route
						path='/archive'
						element={
							<Main
								type={'archive'}
								resetSelectedEmail={resetSelectedEmail}
								handleItemClick={handleItemClick}
								// deleteEmail={deleteEmail}
								signOut={signOut}
								fetchEmails={fetchEmails}
								updateEmailsOnRead={updateEmailsOnRead}
								updateEmailsOnArchived={updateEmailsOnArchived}
							//handleOtherButtonClick={handleOtherButtonClick}
							/>
						} />
					<Route
						path="/settings"
						element={
							<Settings
								additionalMailAddresses={additionalMailAddresses}
								updateMailAddresses={updateMailAddresses}
								createMailAddress={createMailAddress}
								patterns={patterns}
								temporaryList={temporaryList}
								setTemporaryList={setTemporaryList}
								temporaryPatternList={temporaryPatternList}
								setTemporaryPatternList={setTemporaryPatternList}
								temproraryConfigs={temproraryConfigs}
								setTemproraryConfigs={setTemproraryConfigs}
								signOut={signOut}
								deletelMailAddress={deletelMailAddress}
								createPatterns={createPatterns}
								updatePatterns={updatePatterns}
								deletePatterns={deletePatterns}
								updateTenant={updateTenant}
								updateEmailsOnArchived={updateEmailsOnArchived}
							/>
						} />
					<Route
						path="/plan"
						element={
							<Contract
								signOut={signOut}
							/>
						} />
				</Routes>
			</CurrentUserContext.Provider>

			<div className="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
				<div className="d-flex">
					<div className="toast-body">
						Hello, world! This is a toast message.
					</div>
					<button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" id="liveToast"></button>
				</div>
			</div>
		</div >
	);
}

// 1.サインアップを非表示にする 
// 2.カスタムコンポネントを指定する
// 3.variation：default／modal
export default withAuthenticator(App, {
	hideSignUp: true,
	components: components,
	variation: 'default',
});
