import { css } from '@emotion/react';

export const CheckResultCommentStyles = () => ({
    main: () => css`
        .comment__container {
            display: flex;
            padding: 5px 10px 5px;
            background-color: #f0f0f0;
            margin: 0px 5px 3px;
            border-radius: 8px;
        }
        .comment__grid-item {
            margin-right: 15px;
            font-size: 7px;
            min-width: fit-content;
        }
        .comment__grid-item_comment {
            margin-right: 15px;
            font-size: 7px;
            min-width: auto;
        }
    `,
    statusColor: (status: string) => css`
        padding: 2px 3px;
        background-color: ${ status === 'NG' ? '#f8d7da' : status === 'OK' ? '#d1e7dd' : '' };
        color: ${ status === 'NG' ? '#dc3545' : status === 'OK' ? 'green' : '' };
        border-radius: 3px;
        font-weight: 400;
    `
})