import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {$EmailInfo, $EmailInfo$Client, $EmailInfo$Client$Latest} from '../../graphql/queriesIO'

type $User = {
  email:    string,
  tenant:   string,
  username: string,
}
type $Status = 'ng_link'|'unprocessed_link'
type $Filter = undefined | ((item: $EmailInfo$Client) => boolean)

type $Context = {
  enabledThreadMode: boolean,
  setEnabledThreadMode: (value: boolean) => void,
  filters: {
    subject: string,
    sender:  string,
    status:  Array<$Status>,
  },
  enabledFilterNg:          boolean,
  enabledFilterUnprocessed: boolean,
  variation: {
    original: {
      allItems: Array<$EmailInfo$Client>,
    },
    notArchived: {
      sharedBox: {
        filteredItems:    Array<$EmailInfo$Client>,
        // allItems:         Array<$EmailInfo$Client>,
      },
      personalBox: {
        filteredItems:    Array<$EmailInfo$Client>,
        // allItems:         Array<$EmailInfo$Client>,
      },
    },
    archived: {
      filteredItems:    Array<$EmailInfo$Client>,
    }
  },
  setOriginalList:          (value: Array<$EmailInfo>) => void,
  setFilterSubject:         (value: string) => void,
  setFilterSender:          (value: string) => void,
  toggleFilterStatus:       (value: $Status) => void,
  setCurrentUser:           (value: $User)  => void,
}


const initial: $Context = {
  enabledThreadMode: true,
  setEnabledThreadMode: () => {},
  filters: {
    subject: '',
    sender:  '',
    status:  [],
  },
  enabledFilterNg:          false,
  enabledFilterUnprocessed: false,
  variation: {
    original: {
      allItems: [],
    },
    notArchived: {
      sharedBox: {
        filteredItems:    [],
      },
      personalBox: {
        filteredItems:    [],
      },
    },
    archived: {
      filteredItems:    [],
    }
  },
  setOriginalList:          () => {},
  setFilterSubject:         () => {},
  setFilterSender:          () => {},
  toggleFilterStatus:       () => {},
  setCurrentUser:           () => {},
}

const Context = createContext(initial)

const enabledThreadModePrevKey = 'gma.linkchecker.enabledThreadMode'

export const EmailInfoListDataContextProvider = React.memo<PropsWithChildren>((props) => {
  const [enabledThreadMode, _setEnabledThreadMode] = useState(initial.enabledThreadMode)
  useEffect(() => {
    const prevValue = localStorage.getItem(enabledThreadModePrevKey)
    if (prevValue === null) return
    _setEnabledThreadMode(prevValue.toLowerCase() === 'true')
  }, [])
  const setEnabledThreadMode = useCallback<typeof _setEnabledThreadMode>((value) => {
    localStorage.setItem(enabledThreadModePrevKey, value.toString() )
    _setEnabledThreadMode(value)
  }, [])

  const [originalList, _setOriginalList] = useState<Array<$EmailInfo$Client>>([])
  // number_in_same_mail をクライアントでセットする必要がある為、ラップしておく。
  const setOriginalList = useCallback((value: Array<$EmailInfo>) => {
    const newValue = value.map<$EmailInfo$Client>((item) => ({
      ...item,
      results: !Array.isArray(item.results) ? item.results : item.results, // TODO: なぜか object のパターンがあるっぽいので注意
        // // .sort() // TODO: 各所で sort の設定が違うと number_in_same_mail の割り当てがバグるので、おそらく必要
        // .map((result, index) => ({
        //   ...result,
        //   number_in_same_mail: (index + 1).toString().padStart(4, '0'),
        // })),
      revisionType: 'parallel',
      isMatchedFilter: false,
    }))
    _setOriginalList(newValue)
  }, [_setOriginalList])

  // TODO: 現状 CurrentUserContext の Provider のNest位置の関係でそこを直接参照できない為、臨時でここに state を持たせる
  const [currentUser, setCurrentUser ] = useState<$User|undefined>(undefined)

  /**
   * Filter 機能
   */
  const [filters, _setFilters] = useState<$Context['filters']>(initial.filters)

  const archiveFilterVisible = useCallback((item: $EmailInfo$Client) => {
    return item.archived === 0
  }, [])
  const archiveFilterInvisible = useCallback((item: $EmailInfo$Client) => {
    return item.archived === 1
  }, [])
  const subjectFilter = useMemo<$Filter>(() => {
    if (filters.subject === '') return undefined
    return (item: $EmailInfo$Client) => {
      return item.subject.toLocaleLowerCase().includes(filters.subject.toLocaleLowerCase())
    }
  }, [filters.subject])
  const senderFilter = useMemo<$Filter>(() => {
    if (filters.sender === '') return undefined
    return (item: $EmailInfo$Client) => {
      return item.sender.toLocaleLowerCase().includes(filters.sender.toLocaleLowerCase())
    }
  }, [filters.sender])
  const statusFilter = useMemo<$Filter>(() => {
    if (filters.status.length === 0) return undefined
    return (item: $EmailInfo$Client) => {
      return filters.status.some((key) => (item[key] ?? 0) > 0)
    }
  }, [filters.status])

  const setFilterSubject = useCallback((subject: string) => {
    if (filters.subject === subject) return
    _setFilters({...filters, subject})
  }, [filters])
  const setFilterSender = useCallback((sender: string) => {
    if (filters.sender === sender) return
    _setFilters({...filters, sender})
  }, [filters])
  const toggleFilterStatus = useCallback((status: $Status) => {
    const filterStatus = filters.status.filter((item) => item === status) // [memo] 現状 status は一つしか選択できない仕様をここで改修
    const oldValue = [...filterStatus]
    const newValue = [...filterStatus].filter((item) => item !== status)
    _setFilters({...filters, status: (newValue.length === oldValue.length) ? [...newValue, status] : newValue})
  }, [filters])

  // 注: 同一 array にこのメソッドを複数回実行しない。( isMatchedFilter は毎回判定しなおし、最後の実行回のものしか反映しない仕様にしてるので。）
  const toFiltered = useCallback((items: Array<$EmailInfo$Client>, filters: Array<$Filter>) => {
    const _filters = filters.filter(Boolean) as Array<NonNullable<typeof filters[number]>>
    if (_filters.length === 0) return [...items]
    return items
      // 1. 最初に isMatchedFilter をセットした状態のリストに変換
      .map<$EmailInfo$Client>((item) => {
        const isLatestSomeMatchedFilter = _filters.every((filter) => filter(item))
        if (item.revisionType !== 'latest') return {
          ...item,
          isMatchedFilter: isLatestSomeMatchedFilter,
        }
        const oldRevisions = item.oldRevisions.map((oldItem) => ({
          ...oldItem,
          isMatchedFilter: _filters.every((filter) => filter(oldItem))
        }))
        return {
          ...item,
          oldRevisions,
          isMatchedFilter: isLatestSomeMatchedFilter,
          // ※ スレッド状態の場合は、過去のもので一つでも該当があれば、それも加味する
          isAnyOldRevisionMatchedFilter: oldRevisions.some((_) => _.isMatchedFilter),
        }
      })
      // 2. 最後に最上位のもので絞る
      .filter((item) => (
        (item.isMatchedFilter) ||
        (item.revisionType === 'latest' && item.isAnyOldRevisionMatchedFilter)
      ))
  }, [])


  /**
   * Thread 機能
   */
  const toFilteredThread = useCallback((items: Array<$EmailInfo$Client>, filters: Array<$Filter>): Array<$EmailInfo$Client>  => {
    if (!enabledThreadMode) return toFiltered(items, filters)
    return toFiltered(
      items
        .reduce<Array<$EmailInfo$Client$Latest>>((results, item) => {
          const latest = results.find((exist) => exist.subject === item.subject) as $EmailInfo$Client$Latest | undefined
          if (!latest) {
            return [
              ...results,
              {
                ...item,
                revisionType: 'latest',
                oldRevisions: [],
                isAnyOldRevisionMatchedFilter: false,
              }
            ]
          }
          latest.oldRevisions = [
            ...latest.oldRevisions,
            {
              ...item,
              revisionType: 'old',
              latestRevision: latest,
            }
          ]
          return results
        }, []),
      filters
    )
  }, [enabledThreadMode])

  const variation = useMemo(() => {
    console.log('originalList: ', originalList)
    // isMatchedFilter の判定から除外する filter を先にかけておく
    const notArchivedCommon = originalList.filter(archiveFilterVisible)
    const archivedCommon = originalList.filter(archiveFilterInvisible)
    const notArchivedPerson = notArchivedCommon
      .filter((item) => (
        (currentUser && item.sender.toLocaleLowerCase().includes(currentUser.email.toLocaleLowerCase()))
      ))

      const result = {
        original: {
          allItems: toFilteredThread(originalList, []),
        },
        notArchived: {
          sharedBox: {
            filteredItems: toFilteredThread(notArchivedCommon, [subjectFilter, senderFilter, statusFilter]),
            // allItems:         toFilteredThread(notArchivedCommon, [subjectFilter, senderFilter]),
          },
          personalBox: {
            filteredItems: toFilteredThread(notArchivedPerson, [subjectFilter, statusFilter]),
            // allItems:         toFilteredThread(notArchivedPerson, [subjectFilter]),
          },
        },
        archived: {
          filteredItems: toFilteredThread(archivedCommon, [subjectFilter, statusFilter]),
        }
      }
    console.log(result)
    return result
  }, [
    originalList,
    filters,
    subjectFilter,
    senderFilter,
    statusFilter,
    currentUser,
    enabledThreadMode,
  ])

  // console.log({ variation })

  return (
    <Context.Provider value={{
      setOriginalList,
      enabledThreadMode, setEnabledThreadMode,
      filters,
      enabledFilterNg:          filters.status.includes('ng_link'),
      enabledFilterUnprocessed: filters.status.includes('unprocessed_link'),
      setFilterSubject, setFilterSender, toggleFilterStatus,
      setCurrentUser,
      variation,
    }}>
      {props.children}
    </Context.Provider>
  )
})
EmailInfoListDataContextProvider.displayName = 'EmailInfoListDataContextProvider'

/** @type {() => $Context} */
export const useEmailInfoListDataContext = () => useContext(Context)
