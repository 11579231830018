//@ts-check
import React from "react";
import './Usage.css';

function Usage() {
    return (
        <>
            {/* <div className="upgrade_container d-flex flex-column bg-body shadow my-3 px-3">
                <div className="upgrade_current-plan-container d-flex flex-column pb-3">
                    <div className='set-item_title-area d-flex flex-row pb-0 pt-3'>
                        <h3 className='set-item_title me-auto px-2 pb-2 fw-semibold'>利用状況</h3>
                    </div>
                    <div className="plan_content-block border-1 align-self-center w-100 d-flex flex-column align-items-center">
                        <h4 className="fs-6">ベーシックプラン</h4>
                        <div className="d-flex flex-row justify-content-evenly w-100">
                            <div className="upgrade_payment-container p-3 d-flex flex-row udgrade-custom-font-13">
                                <div className="border-end border-1 fw-bold p-2 d-flex flex-column justify-content-center">
                                    <p className="mb-1 text-end">請求サイクル</p>
                                    <p className="text-end mb-1">請求金額</p>
                                </div>
                                <div className="p-2 d-flex flex-column justify-content-center">
                                    <p className="mb-1">月次</p>
                                    <p className="mb-1">￥20,000（税抜き）</p>
                                </div>
                            </div>
                            <div className="upgrade_details-container p-3 d-flex flex-row udgrade-custom-font-13">
                                <div className="border-end border-1 fw-bold p-2 d-flex flex-column justify-content-center">
                                    <p className="mb-1 text-end">契約開始日</p>
                                    <p className="text-end mb-1">契約終了日</p>
                                    <p className="text-end mb-1">通数</p>
                                    <p className="text-end mb-1">アカウント数</p>
                                    <p className="text-end mb-1">通知先</p>
                                </div>
                                <div className="p-2 d-flex flex-column justify-content-center">
                                    <p className="mb-1">2023年4月3日 09:00</p>
                                    <p className="mb-1">2023年10月3日 08:59</p>
                                    <p className="mb-1">231／300通＋100通</p>
                                    <p className="mb-1">3／5個</p>
                                    <p className="mb-1">5／5個　MAX</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="usage__table mt-5">
                <table className="table table-sm table-bordered">
                    <tbody className="udgrade-custom-font-11">
                        <tr>
                            <th className="w-25 ps-3 bg-body-secondary border-start-0" scope="row">利用プラン</th>
                            <td className="border-end-0" colSpan={2}>スタンダード</td>
                        </tr>
                        <tr>
                            <th className="w-25 ps-3 bg-body-secondary border-start-0" scope="row">契約開始日</th>
                            <td className="border-end-0" colSpan={2}>2023年4月3日</td>
                        </tr>
                        <tr>
                            <th className="w-25 ps-3 bg-body-secondary border-start-0" scope="row">契約終了日</th>
                            <td className="border-end-0">2023年10月2日</td>
                        </tr>
                        <tr>
                            <th className="w-25 ps-3 bg-body-secondary border-start-0" scope="row">通数</th>
                            <td className="border-end-0">231通／300通＋100通</td>
                        </tr>
                        <tr>
                            <th className="w-25 ps-3 bg-body-secondary border-start-0" scope="row">アカウント数</th>
                            <td className="border-end-0">3個／5個</td>
                        </tr>
                        <tr>
                            <th className="w-25 ps-3 bg-body-secondary border-start-0" scope="row">通知先</th>
                            <td className="border-end-0">5個／5個　MAX</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* <div className="upgrade_choose-plan-container">
                <h3 className='set-item_title me-auto pb-2 pt-3 pb-4 fw-semibold'>アップグレードメニュー</h3>
            </div> */}
            {/* <PlanCards /> */}
        </>
    )
} export default Usage;