import {useCallback, useRef, useState, MutableRefObject} from 'react'

function uuidV4() {
  return window.crypto.randomUUID()
}

export function useRefState<State>(initialValue: State): [MutableRefObject<State>, (value: State) => void] {
  const ref = useRef<State>(initialValue)

  const [_, setTrigger] = useState<string>('')
  const setter = useCallback((value: State) => {
    ref.current = value
    setTrigger(uuidV4())
  }, [])

  return [ref, setter]
}
