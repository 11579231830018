/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Button from 'react-bootstrap/Button';

function CustomToggleButton({ eventKey, callback, name }) {
    const decoratedOnClick = useAccordionButton(eventKey, callback);

    return (
        <Button
            type="button"
            css={css`
                height: 35px;
                width: 70px;
                justify-self: end;
                font-size: 12px;`}
            className="req-desc_button p-0 mx-2"
            variant="success"
            onClick={decoratedOnClick}
        >
            {name}
        </Button>
    );
}

export default CustomToggleButton;