import { css } from '@emotion/react';

export const EmailContentStyles = () => ({
    container: () => css`
        overflow-wrap: anywhere;
        overflow-y: scroll;
    `,
    main: () => css`
    .email-content__plain-text {
        white-space: pre-line;
    }

    .email-content__infos {
        font-size: 12px;
    }

    .email-content__body-area {
        font-size: 14px;
    }

    .email-content__checkbox-lable {
        font-size: 10px;
    }
    `
})