import { css } from '@emotion/react';

export const SearchBarStyles = () => ({
    searchBar: () => css`
    padding-right: 20px;
    height: 50px;
    `,
    searchBarBtns: () => css`
    transition: .2s ease-in-out 0s;
    background-color: #f0f0f0;
    `,
    searchBarBtnsHover: (isNg) => css`
    &:hover {
        ${!isNg ? 'background-color: #ced0d2;' : 'background-color: #f8d7da;'}
    }
    `,
    fontSize12: () => css`
    font-size: 12px;
    `,
    toggleThreadMode: () => css`
        margin: 0 0 0 25px; 
        font-size: 10px;
    `,
});