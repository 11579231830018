//@ts-check
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as idb from '../../utils/indexeddb';
import React, { useContext, useState } from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import Header from '../Header/Header';
import SearchBar from '../SearchBar/SearchBar';
import Container from '../Container/Container';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { useEmailInfoListDataContext } from '../../contexts/data/EmailInfoListDataContext'
import { archiveTargetListDataContext } from '../../contexts/data/ArchiveTargetListDataContext'

function Main({
    type, // 'sharedBox' | 'personalBox' | 'archive'
    handleItemClick,
    isNextToken,
    signOut,
    toggleshowToast,
    fetchEmails,
    resetSelectedEmail,
    updateEmailsOnRead,
    updateEmailsOnArchived,
}) {
    const emailInfoListDataContext = useEmailInfoListDataContext()
    const archiveTargetList = archiveTargetListDataContext.useConsumer()

    const [currentPage, setCurrentPage] = useState(1);
    const [emailsPerPage] = useState(10);
    const [isEmailInfoLoading, setIsEmailInfoLoading] = useState(false);
    const [isLoadingOnArchived, setIsLoadingOnArchived] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0);

    const { currentUser } = useContext(CurrentUserContext);

    // @ts-ignore
    const targetData = type === 'archive' ? emailInfoListDataContext.variation.archived : emailInfoListDataContext.variation.notArchived[type]

    const filteredData = targetData.filteredItems

    console.log('filteredData: ', filteredData)

    const handleRefresh = async () => {
        await setCurrentPage(1);
        await idb.clearDb()
        await fetchEmails(currentUser.tenant, false);
        await setRefreshKey((prevKey) => prevKey + 1);
        await archiveTargetList.setItems([])
        if (!isNextToken) {
            setTimeout(() => {
                setIsEmailInfoLoading(false)
            }, 1000);
        }
    }


    const indexOfLastEmail = currentPage * emailsPerPage;
    const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        archiveTargetList.setItems([])
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
            archiveTargetList.setItems([])
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(filteredData.length / emailsPerPage)) {
            setCurrentPage(currentPage + 1);
            archiveTargetList.setItems([])
        }
    };

    // function filterBySearchWord(list, word) {
    //     if (list.length > 0) {
    //         return list.filter((item) => item.subject !== null && item.subject.toLowerCase().includes(word.toLowerCase()));
    //     } else {
    //         return [];
    //     }
    // }

    // function handleFilter(word) {
    //     const list = filterBySearchWord(emailList, word);

    //     if (list !== null && list.length !== 0) {
    //         setFilteredList(list);
    //     } else {
    //         setFilteredList([]);
    //     };

    //     console.log(`Filtered List: ${list}`);
    // }

    const handleFilterReset = () => {
        resetSelectedEmail();
        setCurrentPage(1);
        archiveTargetList.setItems([])
    }

    const handleNGFilter = () => {
        setCurrentPage(1)
        emailInfoListDataContext.toggleFilterStatus('ng_link')
        archiveTargetList.setItems([])
    }
    const handleUnprocessedFilter = () => {
        setCurrentPage(1)
        emailInfoListDataContext.toggleFilterStatus('unprocessed_link')
        archiveTargetList.setItems([])
    }

    // useEffect(() => {
    //     setFilteredList([])
    //     setNGList([]);
    //     setIsNGButtonActive(false)
    //     resetSelectedEmail();
    // }, [])

    return (
        <>
            <NavigationBar
                handleFilterReset={handleFilterReset} />
            <div css={css`
                overflow-x: auto
            `} className='d-flex flex-column w-100'>
                <Header
                    signOut={signOut} />
                <SearchBar
                    handleNGFilter={handleNGFilter}
                    isNGButtonActive={emailInfoListDataContext.enabledFilterNg}
                    handleUnprocessedFilter={handleUnprocessedFilter}
                    isUnprocessedButtonActive={emailInfoListDataContext.enabledFilterUnprocessed}
                    emailsPerPage={emailsPerPage}
                    totalEmails={filteredData.length}
                    paginate={paginate}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    currentPage={currentPage}
                    isEmailInfoLoading={isEmailInfoLoading}
                    isLoadingOnArchived={isLoadingOnArchived}
                    setIsEmailInfoLoading={setIsEmailInfoLoading}
                    setIsLoadingOnArchived={setIsLoadingOnArchived}
                    handleRefresh={handleRefresh}
                    updateEmailsOnArchived={updateEmailsOnArchived}
                    disableSenderFilter={type === 'personalBox'}
                    isNextToken={isNextToken}
                />
                <Container
                    emailList={filteredData.slice(indexOfFirstEmail, indexOfLastEmail)}
                    isEmailInfoLoading={isEmailInfoLoading}
                    refreshKey={refreshKey}
                    handleItemClick={handleItemClick}
                    updateEmailsOnRead={updateEmailsOnRead} />
            </div>
        </>
    )
}

export default Main;