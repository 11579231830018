import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { EmailInfoListDataContextProvider } from './contexts/data/EmailInfoListDataContext'
import { currentCheckResultListContext, tempCheckResultListContext } from './contexts/data/CheckResultListDataContext'
import { currentEmailInfoItemContext } from './contexts/data/EmailInfoItemDataContext'
import { RecordUpdateContext } from './contexts/data/CheckResultUpdateDataContext'
import {archiveTargetListDataContext} from './contexts/data/ArchiveTargetListDataContext'

const DataContextProviders = React.memo((props) => {
  const Providers = [
    EmailInfoListDataContextProvider,
    currentEmailInfoItemContext.Provider,
    currentCheckResultListContext.Provider,
    tempCheckResultListContext.Provider,
    archiveTargetListDataContext.Provider,
    RecordUpdateContext.Provider,
  ]
  return Providers.reverse().reduce((result, Provider) => (
    <Provider>{result}</Provider>
  ), [props.children])
})
DataContextProviders.displayName = 'DataContextProviders'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <DataContextProviders>
      <App />
    </DataContextProviders>
  </BrowserRouter>
);
