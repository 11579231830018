import { css } from '@emotion/react';

export const EmailItemStyles = () => ({
    /** @type {(params: { isNested: boolean }) => import('@emotion/serialize').SerializedStyles} */
    root: (params) => css`
        ${params.isNested ? css`
            margin: 0;
        ` : css`
            margin-right: .25rem;
            margin-left: .25rem;
        `}
    `,
    /** @type {(params: { isSelected: boolean, isMatchedFilter: boolean, isLatestOfSelected, isRead: boolean }) => import('@emotion/serialize').SerializedStyles} */
    elementContainer: ({isSelected, isMatchedFilter, isLatestOfSelected, isRead}) => css`
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 7px;
        ${isSelected ? css`
            // tailwind の bg-success-subtle から移行
            background-color: #d1e7dd;
        ` : isLatestOfSelected ? css`
            background-color: #d1e7dd99;
        ` : isRead ? css`
            // tailwind の bg-body-secondary から移行
            background-color: rgba(233,236,239,1);
        ` : css`
            // tailwind の bg-white から移行
            background-color: white;
        `}
        ${isMatchedFilter && css`
            box-shadow: inset 0 0 0 2px #f8d7da;
        `}
        &:hover {
            cursor: pointer;
            ${!isSelected && css`
                background-color: #cfcfcf;
            `}
            transition: .2s ease-in-out 0s;
        }
    `,
    elementContainerCheck: () => css`
      margin: 0 0 0 1em;
      input {
        width: 2em;
        height: 2em;
      }
    `,
    elementContainerArrowIcon: () => css`
      width: 2.5em;
      height: 2.5em;
      user-select: none;
      svg {
        color: darkgray;
        width: 100%;
        height: 100%;
      }
    `,
    elementContainerEmailIcon: () => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      color: green;
    `,
    elementContainerEmailIconText: () => css`
      margin: 0 0 -1.5em;
      font-weight: bold;
    `,
    main: () => css`
        .email-item__info-container,
        .email-item__sammaries,
        .email-item__time-stamp {
            justify-self: start;
        }

        .email-item__time-stamp,
        .email-item__button,
        .email-item__title {
            font-size: 12px;
        }

        .email-item__inner-container {
            flex: 1;
            display: grid;
            grid-template-columns: 10% 38% 20% 12% 15%;
            grid-gap: 3px;
            grid-auto-flow: row;
            font-size: 7px;
            align-items: center;
        }

        .email-item__icon,
        .email-item__info-container,
        .email-item__sammaries,
        .email-item__button {
            align-self: center;
        }

        .email-item__email {
            font-size: 11px;
        }

        .email-item__button {
            width: 70px;
            height: 35px;
            font-size: 13px;
            justify-self: end
        }

        .email-item__icon {
            justify-self: center;
        }

        .email-item__info-container {
            overflow-wrap: anywhere;
        }

        .email-item__results-area {
            border: none;
        }
    `,
    /** @type {(params: { visible: boolean }) => import('@emotion/serialize').SerializedStyles} */
    revisionSection: (params) => css`
        padding: 0 0 0 20px;
        overflow: hidden;
        background-color: #f8f9fa;
        ${!params.visible && css`
          display: none;
        `}
        // accordion 部分は js での対応にしておく。
    `,
    revisionList: () => css`
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 0;
      padding: 5px 0;
    `,
    revisionItem: () => css`
    `,
})